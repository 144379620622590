import React from "react";
import indiaFlag from "../../../assets/images/flags/india.png"
import ukFlag from "../../../assets/images/flags/uk.png"
export const overDraftBanksColumns = (onEditDraft, confirmDelete) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "bl_id ",
    },
    {
        Header: "Bank Name",
        accessor: "bank_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_bname",
        Cell: cellProps => {
            const location = cellProps?.row?.original?.location
            return (
                <div>
                    <img
                        src={location == "1" ? indiaFlag : ukFlag}
                        value={location}
                        className=" me-3 object-fit-cover"
                        width={20}
                        height={15}
                        alt="flag"
                    />
                    {cellProps?.row?.original?.bank_name}
                </div>
            )
        },
    },
    {
        Header: "Account Number",
        accessor: "account_no",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_accno",
    },
    {
        Header: "Rate (%)",
        accessor: "interest_rate",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_interest",
    },
    {
        Header: "Avl. Limit",
        accessor: "credit_limit",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_interest",
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        className: "action-width-shift text-center",
        Cell: cellProps => {
            return (
                <div>
                    <button
                        onClick={() => onEditDraft(cellProps?.row?.original)}
                        className="btn action-btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </button>
                    <a
                        onClick={() => confirmDelete(cellProps?.row?.original?.id)}
                        className="btn btn-sm action-btn btn-danger"
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]

export const overDraftOverview = (onViewInterest) => [
    // {
    //     Header: "ID",
    //     accessor: "value",
    //     isShortIcon: true,
    //     className: "bl_id ",
    // },
    {
        Header: "Overdraft",
        accessor: "bank_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_bname",
        Cell: cellProps => {
            const overdraft = cellProps?.row?.original
            return (
                <div>
                    {overdraft?.label}<br />
                    <small> <i className="bx bx-subdirectory-right fs-6"></i>{overdraft?.account_no}</small>
                </div>
            )
        },
    },
    {
        Header: "Avl. Limit",
        accessor: "credit_limit",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_accno",
    },
    {
        Header: "Used Amt.",
        accessor: "used_limit",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_interest",
    },
    {
        Header: "Balance Amt.",
        accessor: "bal_limit",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_interest",
    },
    {
        Header: "Interest Amt.",
        accessor: "interest_amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: "bl_interest",
        Cell: cellProps => {
            return(
                <div className="text-center">{Number(cellProps?.row?.original?.interest_amount).toFixed(2)}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: true,
        isShortIcon: true,
        className: "",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center" onClick={() => onViewInterest(cellProps?.row?.original)}>
                    <button className="btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-eye"></i>
                    </button>
                </div>
            )
        }
    },
]

export const overDraftEntries = (confirmDelete) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center bl-id",
    },
    {
        Header: "Overdraft",
        accessor: "overdraft",
        isInfoIcon: true,
        isShortIcon: true,
        className: "exp-4",
        Cell: cellProps => {
            return (
                <span>
                    {cellProps.row.original.bank_name} <br />
                    <small>
                        <i className="bx bx-subdirectory-right fs-6"></i>{cellProps.row.original.account_no}
                    </small>
                </span>
            )
        },
    },
    {
        Header: "Amount",
        accessor: "amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center exp-6",
    },
    {
        Header: "Type",
        accessor: "type",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center exp-6",
        Cell: cellProps => {
            const type = cellProps.row?.original?.type
            return (
                <div>{type == 0 ? "Withdraw" : 'Return'}</div>
            )
        }
    },
    {
        Header: "Rate(%)",
        accessor: "interest_rate",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center exp-8",
    },
    {
        Header: "Action",
        accessor: "action",
        classNames: "text-center",
        Cell: cellProps => {
            const data = cellProps?.row?.original;
            const createdAt = new Date(data?.created_at);
            const latestEntry = new Date(Math.max(...cellProps?.rows?.map(row => new Date(row.original.created_at))));
            if (createdAt.getTime() === latestEntry.getTime()) {
                return (
                    <div className="d-flex justify-content-center">
                        <button
                            onClick={() => confirmDelete(data?.id)}
                            className="btn btn-sm me-1 btn-outline-danger"
                        >
                            <i className="fas fa-trash-alt text-danger"></i>
                        </button>
                    </div>
                );
            }
            return <div className="text-center">-</div>;
        },
    },

]