import React, { useEffect, useState } from "react"
import classNames from "classnames"
import SimpleBar from "simplebar-react"
import { Modal, Nav, NavItem, NavLink } from "reactstrap"
import DmTodaysTask from "./DmTodaysTask"
import { Link, useNavigate } from "react-router-dom"
import configure from "configure"
import { OverviewOption } from "utils/common-options"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import { getWeekStartAndEnd } from "utils/common-helpers"
import Swal from "sweetalert2"

const DMTaskTimer = () => {
    const [showTask, setShowTask] = useState(false)
    const [boxWidth, setBoxWidth] = useState(0)
    const [isIndex, setIsIndex] = useState(false)
    const [verticalActiveTab, setverticalActiveTab] = useState("1")
    const navigate = useNavigate()
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.98)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    const handleHover = () => {
        setIsIndex(true)
    }

    const handleLeave = () => {
        setIsIndex(false)
    }
    const [isLoading, setLoading] = useState(false)
    const token = secureLocalStorage.getItem("auth_token");
    const [data, setData] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [pending, setPending] = useState([])
    const [sprintStatus, setSprintStatus] = useState(false)
    const toggleLoading = (load) => {
        setLoading(load)
    }


    const getData = async () => {
        const userId = localStorage.getItem('auth_id')
        const res = await fetchData(toggleLoading, `getDigitalProjectTasks/${userId}/${getWeekStartAndEnd()?.startOfWeek}`, token);
        if (res?.success) {
            if (res?.data?.sprint_status == false) {
                Swal.fire({
                    icon: "warning",
                    title: "⚠️ Oops! Sprint Still Pending",
                    text: "🚀 Please complete your sprint to move forward and keep the momentum going! 💪",
                });
            } else {
                setData(res?.data?.todays_tasks)
                setYesterday(res?.data?.yesterday_tasks)
                setPending(res?.data?.pending_tasks)
                setShowTask(true)
            }
        }
    };

    return (
        <div>
            {isLoading && (<Loader />)}
            <a
                onClick={() => {
                    getData()
                }}
                className="pe-3 text-white"
                title="Switch Task"
            >
                <i className="fa fa-stopwatch"></i>
            </a>

            <Modal size="xl" isOpen={showTask} className="modal-fullscreen">
                <div className="bg-white">
                    <div className="modal-header mb-0" style={{
                        border: "none",
                        padding: "10px 35px 15px 15px"
                    }}>
                        <div
                            className="d-flex align-items-center justify-content-between"
                            style={{
                                width: "100%",
                            }}
                        >
                            <h5 className="col-6 modal-title ">Today's Overview</h5>

                            <div className="d-flex justify-content-end align-items-center">
                                <Link className="s btn text-white me-0 btn-primary btn-label"
                                    to={`${configure.appUrl}complete_sprint`}
                                >

                                    <i className="bx bx-run me-2 icon-size label-icon"></i>
                                    Complete Sprint
                                </Link>
                                <button
                                    type="button"
                                    onClick={() => setShowTask(false)}
                                    className="ms-4 mt-2"
                                    style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                        color: "red",
                                    }}
                                >
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            color: "#bf1e30",
                                            fontSize: "28px",
                                            lineHeight: "1px",
                                        }}
                                    >
                                        &times;
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body pe-0 d-flex col-12 pt-0">
                        <div
                            className={`px-0 col-1  dm-overview-width`}
                            style={isIndex ? { zIndex: "2" } : {}}
                        >
                            <div className="position-relative">
                                <div>
                                    <SimpleBar
                                        style={{ height: "55vh" }}
                                        className={`overflow-visible`}
                                    >
                                        <Nav pills className={` flex-column px-1 py-1 h-100`}>
                                            {OverviewOption?.map((obj, i) => {
                                                return (
                                                    <NavItem
                                                        className={classNames({
                                                            "d-flex flex-column gap-2 mb-2": true,
                                                            active: verticalActiveTab == obj.id,
                                                        })}
                                                        key={i}
                                                    >
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classNames({
                                                                "text-center crendential": true,
                                                                active: verticalActiveTab == obj.id,
                                                                "bg-danger-active": obj?.id == 3
                                                            })}
                                                            onClick={e => setverticalActiveTab(obj.id)}
                                                            onMouseEnter={handleHover}
                                                            onMouseLeave={handleLeave}
                                                        >
                                                            {obj?.label}
                                                            <span className={`link_name border-start border-white border-2 ${obj?.id == 3 ? "bg-danger" : ''}`}>
                                                                {obj?.text}
                                                            </span>
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            })}
                                        </Nav>
                                    </SimpleBar>
                                </div>
                            </div>
                        </div>
                        <div className="form-container today-table">
                            <DmTodaysTask verticalActiveTab={verticalActiveTab} activitiesData={verticalActiveTab == 1 ? data : verticalActiveTab == 2 ? yesterday : verticalActiveTab == 3 ? pending : []} />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DMTaskTimer
