import NewPagination from 'components/Common/Newpagination'
import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Card, CardBody, Input, Modal, Row, Table } from 'reactstrap'
import { overDraftOverview } from 'utils/columns/Finance/financeColumns'
import paidLoanImg from "../../../../assets/images/Expenses/paidLoan.jpg"
import { fetchData } from 'utils/common-fetchData'
import moment from 'moment'

const Overview = ({ overDraft, toggleLoading, token }) => {

    const [paidLoan, setPaidLoan] = useState(false)
    const [interestData, setInterestData] = useState([])
    const [allInterestData, setAllInterestData] = useState([])
    const [bankDetail, setBankDetail] = useState({})
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const onViewInterest = async (obj) => {
        setBankDetail(obj)
        const res = await fetchData(toggleLoading, `overdraftInterestData/${obj?.id}`, token);
        if (res?.success) {
            setInterestData(res?.data)
            setAllInterestData(res?.data)
            setPaidLoan(true)
        }
    }
    const columns = useMemo(
        () => overDraftOverview(onViewInterest),
        []
    )
    const data = useMemo(() => overDraft, [overDraft])
    useEffect(() => {
        setPageSize(50)
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const { pageIndex, pageSize, globalFilter } = state
    useEffect(() => setPageSize(50), [])

    const filterByDateRange = (arr, fromDate, toDate) => {
        return arr.filter((item) => {
            const itemDate = new Date(item.date_add);
            const isAfterFromDate = !fromDate || itemDate >= new Date(fromDate);
            const isBeforeToDate = !toDate || itemDate <= new Date(toDate);
            return isAfterFromDate && isBeforeToDate;
        });
    };


    return (
        <div>
            <Card>
                <CardBody>
                    <div>
                        <Row className="m-0  justify-space-between align-items-center">
                            <div className="col-6 mb-3 text-purple fs-5  fw-bold">OverDraft Overviews</div>
                            <div className="d-flex gap-2 col-md-6">
                                <div className="p-0 col-md-4">
                                    <select
                                        className=" form-select "
                                        style={{ width: "100px" }}
                                        value={pageSize}
                                        onChange={e => setPageSize(e.target.value)}
                                    >
                                        {[10, 25, 50, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="p-0 col-md-8">
                                    <div className="search-box my-xxl-0 d-inline-block ">
                                        <div className="position-relative  ">
                                            <label htmlFor="search-bar-0" className="search-label">
                                                <span id="search-bar-0-label" className="sr-only">
                                                    Search this table
                                                </span>
                                                <input
                                                    onChange={e => setGlobalFilter(e.target.value)}
                                                    id="search-bar-0"
                                                    type="text"
                                                    className=" form-control rounded h-100 "
                                                    placeholder={` Search...`}
                                                    value={globalFilter || ""}
                                                />
                                            </label>
                                            <i className="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="">
                            <div className="col-md-12">
                                <Table
                                    className="table table-bordered mt-4"
                                    {...getTableProps()}
                                >
                                    <thead>
                                        {headerGroups.map((headerGroup, i) => (
                                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th
                                                        key={index}
                                                        className={`${column.className} customShortIcon`}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {column.render("Header")}

                                                        {column.isShortIcon && (
                                                            <span
                                                                onClick={() =>
                                                                    column.getSortByToggleProps().onClick()
                                                                }
                                                                className="ms-2 cursor-pointer"
                                                            >
                                                                <i
                                                                    title="Ascending"
                                                                    className={`bi bi-arrow-up info-icon ${column.isSorted &&
                                                                        !column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                                <i
                                                                    title="Descending"
                                                                    className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        )}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr
                                                    className="row-hover"
                                                    {...row.getRowProps()}
                                                    key={i}
                                                >
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <td
                                                                key={index}
                                                                className={`t-col-${index + 1} ${cell.column.className
                                                                    }`}
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render("Cell")}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        {page.length == 0 && (
                                            <tr>
                                                <td className="text-center" colSpan={10}>
                                                    No Data Found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {/* Pagination */}
                        <Row className="justify-content-md-end justify-content-center align-items-center">
                            <NewPagination
                                gotoPage={gotoPage}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                pageSize={pageSize}
                                page={page}
                                data={data}
                            />
                        </Row>
                    </div>
                </CardBody>
            </Card>

            <Modal isOpen={paidLoan} size="xl" className="" centered>
                <div className="modal-header pe-4">
                    <h5 className="modal-title mt-0">All Interest Details</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setPaidLoan(false)
                        }}
                        className="btn-modal-close"
                    >
                        <span aria-hidden="true" className="btn-modal-span">
                            &times;
                        </span>
                    </button>
                </div>

                <div className="modal-body pe-4 my-4 mt-0">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                            <div className="text-center">
                                <img src={paidLoanImg} alt="Addepe" style={{ width: "90%" }} />
                            </div>
                        </div>
                        <div className="col-md-6 d-flex flex-column ">
                            <div className='d-flex gap-3 mb-3'>
                                <p className='text-purple fw-bold'>Bank Name : <span className='text-purple fw-bold'>{bankDetail?.bank_name}</span></p>
                                <p>|</p>
                                <p className='text-purple fw-bold'>Rate of Interest : <span className='text-purple fw-bold'>{bankDetail?.interest_rate} %</span></p>
                            </div>
                            <div className='d-flex gap-2 col-md-12 align-items-center mb-4'>
                                <div className="col-md-4 ps-2">
                                    <Input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                                <div className="col-md-4 ps-2">
                                    <Input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                                <a
                                    className="btn  btn-danger"
                                    style={{
                                        fontSize: "14px",
                                        backgroundColor: "#fed36d",
                                        borderColor: "#fed36d",
                                        color: "black",
                                    }}
                                    onClick={() => {
                                        setFromDate('')
                                        setToDate('')
                                        setInterestData(allInterestData)
                                    }}
                                >
                                    Clear
                                </a>
                                <button className="btn  btn-save me-1" onClick={() => {
                                    setInterestData(filterByDateRange(allInterestData, fromDate, toDate))
                                }}>
                                    <i className="fas fa-filter search-icon label-icon"></i>
                                </button>
                            </div>
                            <div
                                className={`table-responsive col-md-12 leave-history-slots`}
                            >
                                <Table className="table mb-0">
                                    <thead
                                        className="bg-white"
                                        style={{ position: "sticky", top: 0 }}
                                    >
                                        <tr className=" ">
                                            <th className="header text-center col-1">
                                                Id
                                            </th>
                                            <th className="header col-1">Date</th>
                                            <th className="header col-1">(%) Per Day</th>
                                            <th className="header col-1">Interest Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {interestData?.map((obj, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th scope="row" className="text-center">
                                                        {i + 1}
                                                    </th>
                                                    <td>
                                                        {moment(obj.date_add).format('DD-MM-YYYY')}
                                                    </td>
                                                    <td className='text-center'>
                                                        {Number(obj?.day_rate).toFixed(2)} %
                                                    </td>
                                                    <td className='text-center'>
                                                        {Number(obj?.interest_amount).toFixed(2)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                {interestData?.length == 0 ? (
                                    <p className='text-center text-purple  mt-2'>No Data is Available</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Overview
