import moment from 'moment';
import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DigitalServiceOptions, projectStatus } from 'utils/common-options';

export const digitalColumns = (onDelete, onEditPermission, onViewPermission, onChangeStatusProject) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "company",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
    },
    {
        Header: "Service",
        title: "Status",
        accessor: "project_type",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            const services = cellProps?.row?.original?.project_type?.split(",");
            const allService = DigitalServiceOptions?.filter((obj) => services?.includes(obj?.id))
            return (
                <div className="d-flex gap-2 align-items-center justify-content-center">
                    {allService?.map((obj, i) => {
                        return (
                            <a key={i} className={`fs-5 ${obj?.text}-color`}>
                                <i className={`${obj?.icon} ${obj?.text}-color`}></i>
                            </a>
                        )
                    })}
                </div>
            )
        }

    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.start_date).format("DD-MM-YYYY") || "-"}</>
            )
        },
    },
    {
        Header: "End Date",
        title: "End Date",
        accessor: "end_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "due_date_box text-center",
        Cell: cellProps => {
            return (
                <>
                    {moment(cellProps.row.original.end_date).format("DD-MM-YYYY") || "-"}
                </>
            )
        },
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div className="" style={{ width: "100%" }}>
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row.original.project_status}
                        // onChange={(e) => handleChangeStatus(e, cellProps.row.original?.id)}
                        onChange={(e) =>
                            onChangeStatusProject(cellProps?.row?.original?.id, 2, e?.target?.value)}
                    >
                        {projectStatus?.map((list, index) => {
                            return (
                                <option key={index} value={list.value} className="">
                                    {list.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
            const row = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="View Project"
                        onClick={() => onViewPermission(row)}
                    >
                        <i className="fas fa-eye"></i>
                    </a>

                    <a
                        onClick={() => onEditPermission(row?.id)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Project"
                    >
                        <i className="fas fa-pen "></i>
                    </a>

                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                title={row?.freeze_status == 0 ? "Pause Task" : "Play Project"}
                                className="dropdown-item"
                                onClick={() => onChangeStatusProject(cellProps?.row?.original?.id, 1, cellProps?.row?.original?.freeze_status == 0 ? 1 : 0)}
                            >
                                <i className={row?.freeze_status == 0 ? "bi bi-pause-circle-fill pr-10" : "bi bi-play-circle-fill pr-10"}></i>
                                {row?.freeze_status == 0 ? "Pause Project" : "Play Project"}
                            </a>
                            <a
                                title="Delete Task"
                                className="dropdown-item"
                                onClick={() => onDelete(cellProps?.row?.original?.id)}
                            >
                                <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                Delete Project
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]