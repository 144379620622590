import getChartColorsArray from 'components/Common/ChartsDynamicColor';
import Loader from 'pages/Separate/Loader';
import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import secureLocalStorage from 'react-secure-storage';
import { Card, CardBody } from 'reactstrap'
import { fetchData } from 'utils/common-fetchData';

const OverdraftReport = () => {
    const dataColors = '["--bs-danger", "--bs-success" , "--bs-primary",  "--bs-warning"]';
    const spineareaChartColors = getChartColorsArray(dataColors);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const series = useMemo(() => data, [data])

    const options = {
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        colors: spineareaChartColors,
        xaxis: {
            type: "",
            categories: [
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
                "Jan",
                "Feb",
                "Mar",
            ],
            labels: {
                show: true,
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        tooltip: {
            x: {
                format: "dd/MM/yy HH:mm",
            },
        },
    };
    const toggleLoading = (loading) => {
        setLoading(loading);
    }
    const token = secureLocalStorage.getItem('auth_token')
    const fetchODs = async () => {
        const res = await fetchData(toggleLoading, 'overdraftInterestData', token);
        const updatedArray = res.map(item => ({
            name: item.name,
            data: item.data.map(value => parseFloat(value.toFixed(2)))
        }));
        setData(updatedArray)
    }

    useEffect(() => {
        fetchODs()
    }, [])

    return (
        <div>
            {loading && (<Loader />)}
            <Card>
                <CardBody>
                    <div className="col-6 mb-3 text-purple fs-5  fw-bold">OverDraft Overview</div>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height="450"
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default OverdraftReport
