import React, { useEffect, useState } from "react"
import { Container, Form, Row } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import { ResizableBox } from "react-resizable"
import Draggable from "react-draggable"
import packageImg from "../../../../assets/images/Digital Marketing/DmPackage.jpg"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import { DigitalServiceOptionsDrop, ResourceType } from "utils/common-options"
import { calculateEndDate } from "utils/common-helpers"

const DMBasicProjectDetails = ({ getBasicDetail }) => {
  //   All useState
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [fixButtons, setFixButtons] = useState(false)
  const [customerOptions, setCustomerOptions] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [duration, setDuration] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])
  // Api Integration
  const [projectBelong, setProjectBelong] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState(null)
  const [projectSource, setProjectSource] = useState(null)
  const [projectReferenceOption, setProjectReferenceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectCustomer, setProjectCustomer] = useState(null)
  const [projectResourceType, setProjectResourceType] = useState(null)
  const [projectIndustry, setProjectIndustry] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [projectKeyManager, setProjectKeyManager] = useState(null)
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [SEOMembers, setSEOMembers] = useState([])
  const [SMMMembers, setSMMMembers] = useState([])
  const [PPCMembers, setPPCMembers] = useState([])
  const [EMMembers, setEMMembers] = useState([])
  const [seoPackage, setSeoPackage] = useState([])
  const [smmPackage, setSmmPackage] = useState([])
  const [ppcPackage, setPpcPackage] = useState([])
  const [emPackage, setEmPackage] = useState([])
  const [seoPackageOption, setSeoPackageOption] = useState([])
  const [ssmPackageOption, setSsmPackageOption] = useState([])
  const [ppcPackageOption, setPpcPackageOption] = useState([])
  const [emPackageOption, setEmPackageOption] = useState([])
  const [sourceOption, setSourceOption] = useState([])
  const [serviceDeveloperOption, setServiceDeveloperOption] = useState([])
  const [boxWidth, setBoxWidth] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState("")
  const [selectedQuantity, setSelectedQuantity] = useState("")
  const [selectedWeek, setSelectedWeek] = useState("")
  const [showPopup, setShowPopup] = useState(false)
  const [seoCustomPackageId, setSeoCustPackageId] = useState(null)
  const [smmCustomPackageId, setSmmCustPackageId] = useState(null)
  const [ppcCustomPackageId, setPpcCustPackageId] = useState(null)
  const [emCustomPackageId, setEmCustPackageId] = useState(null)
  const [formRows, setFormRows] = useState([{}, {}, {}, {}, {}, {}, {}])
  const [allActivities, setAllActivities] = useState([])
  const [packageService, setPackageService] = useState("")
  const [functionType, setFunctionType] = useState("")

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const Duration_Options = [
    { label: "1 Month", value: "1" },
    { label: "2 Month", value: "2" },
    { label: "3 Month", value: "3" },
    { label: "6 Month", value: "6" },
  ]

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight
      setBoxHeight(viewportHeight * 0.9)
    }
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
    }
    updateWidth()
    updateHeight()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth, updateHeight)
  }, [])
  // For Client information
  const navigate = useNavigate()
  const params = useParams()

  document.title = "Add Project | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 360) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  useEffect(() => {
    fetchGeneralData()
    fetchActivities()
  }, [])
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const toogleLoad = () => {}
  const auth_token = secureLocalStorage.getItem("auth_token")
  const fetchActivities = async () => {
    const res = await fetchData(toogleLoad, "digitalActivities", auth_token)
    if (res?.success) {
      setAllActivities(
        res?.data?.map(obj => ({
          value: obj.id,
          label: obj?.activity,
          service: obj?.service,
        }))
      )
    }
  }

  const handleSelectChange = (selectedOption, index) => {
    const newFormRows = [...formRows]
    newFormRows[index].activity = selectedOption
    setFormRows(newFormRows)
  }

  const handleQuantityChange = (e, index) => {
    const newFormRows = [...formRows]
    newFormRows[index].quantity = e.target.value
    setFormRows(newFormRows)
  }

  const fetchGeneralData = async () => {
    const response = await FetchPostData(
      toggleLoading,
      "digitalProjectData",
      auth_token,
      ""
    )
    if (response?.data?.success) {
      const {
        location,
        source,
        customer,
        introducer,
        industry,
        keymanager,
        developers,
        packages,
      } = response.data.data
      const mapOptions = (data, valueKey, labelKey) =>
        data?.map(obj => ({
          value: obj[valueKey],
          label: obj[labelKey],
        }))

      setLocationOption(mapOptions(location, "id", "location"))
      const SourceOption = source?.map(obj => ({
        value: obj.id,
        label: obj.leadname,
        customerOption: mapOptions(customer, "id", "company"),
        introducerOption: mapOptions(introducer, "id", "company"),
      }))
      setSourceOption(SourceOption)
      setCustomerOptions(mapOptions(customer, "id", "company"))
      setAllKeyIndustryOption(mapOptions(industry, "id", "name"))
      setProjectKeyManagerOptions(mapOptions(keymanager, "id", "name"))
      setServiceDeveloperOption(mapOptions(developers, "id", "name"))
      const seoPackage = packages
        ?.filter(obj => obj?.service == 1)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
          country: obj?.country,
        }))
      seoPackage?.push({ value: 0, label: "custom package" })
      const ssmPackages = packages
        ?.filter(obj => obj?.service == 2)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
          country: obj?.country,
        }))
      ssmPackages?.push({ value: 0, label: "custom package" })
      const ppcPackage = packages
        ?.filter(obj => obj?.service == 3)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
          country: obj?.country,
        }))
      ppcPackage?.push({ value: 0, label: "custom package" })
      const emPackage = packages
        ?.filter(obj => obj?.service == 4)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
          country: obj?.country,
        }))
      emPackage?.push({ value: 0, label: "custom package" })
      setPpcPackageOption(ppcPackage)
      setSeoPackageOption(seoPackage)
      setSsmPackageOption(ssmPackages)
      setEmPackageOption(emPackage)

      const projectData = await fetchData(
        toggleLoading,
        `digitalProjectData/${params?.id}`,
        auth_token
      )
      if (projectData?.success) {
        const datas = projectData?.data[0]
        getBasicDetail({
          projectType: datas?.project_type,
          projectName: datas?.project_name,
        })
        setProjectDescription(datas?.description)
        setStartDate(datas?.start_date)
        setEndDate(calculateEndDate(datas?.start_date, datas?.duration))
        setProjectBelong(
          mapOptions(location, "id", "location")?.find(
            obj => obj?.value == datas?.location
          )
        )
        setProjectName(datas?.project_name)
        const services = datas?.project_type?.split(",")
        setProjectType(
          DigitalServiceOptionsDrop?.filter(obj =>
            services?.includes(obj.value)
          )
        )
        const source = SourceOption?.find(obj => obj?.value == datas?.source)
        setProjectSource(source)
        const SourceRefer =
          source?.label?.toLowerCase() == "customer"
            ? source?.customerOption
            : source?.label?.toLowerCase() == "introducer"
            ? source?.introducerOption
            : []
        setProjectReferenceOptions(SourceRefer)
        setProjectReferenceOption(
          SourceRefer?.find(obj => obj?.value == datas?.source_id)
        )
        setProjectResourceType(
          ResourceType?.find(obj => obj?.value == datas?.resource_type)
        )
        setProjectCustomer(
          mapOptions(customer, "id", "company")?.find(
            obj => obj?.value == datas?.customer_id
          )
        )
        setProjectIndustry(
          mapOptions(industry, "id", "name")?.find(
            obj => obj?.value == datas?.industry
          )
        )
        setProjectKeyManager(
          mapOptions(keymanager, "id", "name")?.find(
            obj => obj?.value == datas?.kam
          )
        )
        setDuration(
          Duration_Options?.find(obj => obj?.value == datas?.duration)
        )
        const seoPackagess =
          seoPackage?.find(obj => obj?.value == datas?.seo_package) ||
          (datas?.seo_package != null
            ? { value: 0, label: "Custom package" }
            : [])
        setSeoPackage(seoPackagess)
        setSeoCustPackageId(seoPackagess?.value == 0 ? datas?.seo_package : null)
        const smmPackagess =
          ssmPackages?.find(obj => obj?.value == datas?.smm_package) ||
          (datas?.smm_package != null
            ? { value: 0, label: "Custom package" }
            : [])
        setSmmPackage(smmPackagess)
        setSmmCustPackageId(smmPackagess?.value == 0 ? datas?.smm_package : null)
        const ppcPackagess =
          ppcPackage?.find(obj => obj?.value == datas?.ppc_package) ||
          (datas?.ppc_package != null
            ? { value: 0, label: "Custom package" }
            : [])
        setPpcPackage(ppcPackagess)
        setPpcCustPackageId(ppcPackagess?.value == 0 ? datas?.ppc_package : null)
        const emailPackagess =
          emPackage?.find(obj => obj?.value == datas?.em_package) ||
          (datas?.em_package != null
            ? { value: 0, label: "Custom package" }
            : [])
        setEmPackage(emailPackagess)
        setEmCustPackageId(emailPackagess?.value == 0 ? datas?.em_package : null)
        const alldevelopers = mapOptions(developers, "id", "name")
        setSEOMembers(
          alldevelopers?.filter(obj =>
            datas?.seo_assignee?.split(",")?.includes(obj?.value?.toString())
          )
        )
        setSMMMembers(
          alldevelopers?.filter(obj =>
            datas?.smm_assignee?.split(",")?.includes(obj?.value?.toString())
          )
        )
        setPPCMembers(
          alldevelopers?.filter(obj =>
            datas?.ppc_assignee?.split(",")?.includes(obj?.value?.toString())
          )
        )
        setEMMembers(
          alldevelopers?.filter(obj =>
            datas?.em_assignee?.split(",")?.includes(obj?.value?.toString())
          )
        )
      }
    }
  }

  const Activity_Options = [
    { label: "SEO", value: "SEO" },
    { label: "SMM", value: "SMM" },
    { label: "PPC", value: "PPC" },
    { label: "EM", value: "EM" },
  ]

  const quantity_Options = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ]
  const week_Options = [
    { label: "1 week", value: "1 week" },
    { label: "2 week", value: "2 week" },
    { label: "3 week", value: "3 week" },
    { label: "4 week", value: "4 week" },
  ]

  const handleSubmitBasicDetails = async e => {
    e.preventDefault()
    if (
      projectBelong != null &&
      projectType != null &&
      projectSource != null &&
      projectResourceType != null &&
      projectIndustry != null
    ) {
      const auth_token = secureLocalStorage.getItem("auth_token")
      const formEntries = {
        user_id: localStorage.getItem("auth_id"),
        location: projectBelong?.value || "",
        project_name: projectName,
        project_type: projectType?.map(obj => obj?.value)?.join(",") || "",
        source: projectSource?.value || "",
        source_id: projectReferenceOption?.value || "",
        resource_type: projectResourceType?.value || "",
        customer_id: projectCustomer?.value || "",
        industry: projectIndustry?.value || "",
        description: projectDescription || "",
        kam: projectKeyManager?.value || "",
        start_date: startDate,
        end_date: endDate,
        duration: duration?.value || "",
        report_date: startDate,
        seo_assignee: SEOMembers?.map(obj => obj?.value)?.join(",") || "",
        smm_assignee: SMMMembers?.map(obj => obj?.value)?.join(",") || "",
        ppc_assignee: PPCMembers?.map(obj => obj?.value)?.join(",") || "",
        em_assignee: EMMembers?.map(obj => obj?.value)?.join(",") || "",
        seo_package:
          seoPackage?.value == 0 ? seoCustomPackageId : seoPackage?.value || "",
        smm_package:
          smmPackage?.value == 0 ? smmCustomPackageId : smmPackage?.value || "",
        ppc_package:
          ppcPackage?.value == 0 ? ppcCustomPackageId : ppcPackage?.value || "",
        em_package:
          emPackage?.value == 0 ? emCustomPackageId : emPackage?.value || "",
        project_id: params?.id,
      }

      const response = await FetchPostData(
        toggleLoading,
        "editDigitalProjects",
        auth_token,
        formEntries
      )
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Project Edited successfully.",
        }).then(() => {
          navigate(`${configure.appUrl}work/digital_marketing`)
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  const [packageId, setPackageID] = useState("")
  const onAddCustomPackage = async () => {
    const formEntries = {
      user_id: localStorage.getItem("auth_id"),
      country: projectBelong?.value || 1,
      name: `${projectName}-custom-package`,
      activity_id: formRows?.map(obj => obj?.activity?.value)?.join(","),
      quantity: formRows?.map(obj => obj?.quantity)?.join(","),
      service: packageService,
      type: 2,
      package_id: packageId,
    }
    const response = await FetchPostData(
      toggleLoading,
      `${
        functionType == "edit" ? "editDigitalPackages" : "addDigitalPackages"
      }`,
      auth_token,
      formEntries
    )
    if (response?.data?.success) {
      const datas = response?.data?.data
      setShowPopup(false)
      setFormRows([{}])
      if (functionType != "edit") {
        if (packageService == 1) {
          setSeoCustPackageId(datas?.package_id)
        } else if (packageService == 2) {
          setSmmCustPackageId(datas?.package_id)
        } else if (packageService == 3) {
          setPpcCustPackageId(datas?.package_id)
        } else if (packageService == 4) {
          setEmCustPackageId(datas?.package_id)
        }
      }
    }
  }

  const onGetCustomData = async (id, type) => {
    setPackageService(type)
    setFunctionType("edit")
    const res = await fetchData(
      toggleLoading,
      `getCustomPackages/${id}`,
      auth_token
    )
    if (res) {
      const data = res?.find(obj => obj?.service == type)
      setPackageID(data?.id)
      setFormRows(
        data?.activities?.map(obj => ({
          activity: { value: obj?.id, label: obj?.activity, service: type },
          quantity: obj?.quantity,
        }))
      )
      setShowPopup(true)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3">
        <Container fluid>
          <Form className="px-0" onSubmit={handleSubmitBasicDetails}>
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>
              </div>
              {/* Project Basic Details  */}
              <Row className="bg-white py-4 shadow mb-3 px-2">
                <div className="col-md-12 d-flex justify-content-end">
                  <div className="col-md-6 mb-3 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                  </div>
                </div>

                <h5 className="mb-3">Basic Project Details</h5>

                <div className="col-md-2 mt-2 mb-3">
                  <label htmlFor="">
                    Project Belongs To <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Project Belongs To"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location_option}
                    value={projectBelong}
                    onChange={e => setProjectBelong(e)}
                  />
                </div>

                <div className="col-md-6 mt-2 mb-3">
                  <label htmlFor="">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Project Name"
                    className="form-control "
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mt-2 mb-3">
                  <label htmlFor="">
                    Project Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Project Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={DigitalServiceOptionsDrop}
                    closeMenuOnSelect={false}
                    value={projectType}
                    onChange={e => setProjectType(e)}
                    isMulti
                  />
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Source <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={sourceOption}
                      value={projectSource}
                      onChange={e => {
                        setProjectSource(e)
                        if (e.label?.toLowerCase() == "introducer") {
                          setProjectReferenceOptions(e.introducerOption)
                        } else if (e.label?.toLowerCase() == "customer") {
                          setProjectReferenceOptions(e.customerOption)
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">Select {projectSource?.label}</label>
                    <Select
                      placeholder={`Select`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={projectReferenceOptions}
                      value={projectReferenceOption}
                      onChange={e => setProjectReferenceOption(e)}
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Resource Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Resource Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={ResourceType}
                      value={projectResourceType}
                      onChange={e => setProjectResourceType(e)}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Customer <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Customer"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={customerOptions}
                      value={projectCustomer}
                      onChange={e => setProjectCustomer(e)}
                    />
                  </div>

                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Project Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={allKeyIndustryOption}
                      value={projectIndustry}
                      onChange={e => setProjectIndustry(e)}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={projectDescription}
                    onReady={editor => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setProjectDescription(data)
                    }}
                  />
                </div>
              </Row>

              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Members</h5>
                    <div className="col-md-8 mt-2 mb-3">
                      <label htmlFor="">Key Account Manager</label>
                      <Select
                        placeholder="Select Key Account Manager"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={projectKeyManagerOptions}
                        value={projectKeyManager}
                        onChange={e => setProjectKeyManager(e)}
                      />
                    </div>

                    <div className="col-12 mt-2 mb-3 d-flex gap-2 flex-wrap">
                      {projectType?.find(obj => obj?.value == "1") && (
                        <div className="col-12">
                          <label htmlFor="">SEO</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={serviceDeveloperOption}
                            isMulti="true"
                            value={SEOMembers}
                            closeMenuOnSelect={false}
                            onChange={e => setSEOMembers(e)}
                          />
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "2") && (
                        <div className="col-12">
                          <label htmlFor="">SMM</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={serviceDeveloperOption}
                            isMulti
                            closeMenuOnSelect={false}
                            value={SMMMembers}
                            onChange={selectedOption =>
                              setSMMMembers(selectedOption)
                            }
                          />
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "3") && (
                        <div className="col-12">
                          <label htmlFor="">PPC</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={serviceDeveloperOption}
                            isMulti
                            closeMenuOnSelect={false}
                            value={PPCMembers}
                            onChange={selectedOption =>
                              setPPCMembers(selectedOption)
                            }
                          />
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "4") && (
                        <div className="col-12">
                          <label htmlFor="">EM</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={serviceDeveloperOption}
                            isMulti
                            closeMenuOnSelect={false}
                            value={EMMembers}
                            onChange={selectedOption =>
                              setEMMembers(selectedOption)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3 mt-4">Estimatation</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={startDate}
                        onChange={e => {
                          setStartDate(e.target.value)
                          setEndDate(
                            calculateEndDate(e.target.value, duration?.value)
                          )
                        }}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Report Date<span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={startDate}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">Duration </label>
                      <Select
                        placeholder="Select Duration"
                        className="overflow-visible"
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={Duration_Options}
                        value={duration}
                        onChange={e => setDuration(e)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">End Date</label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        disabled
                        value={endDate}
                      />
                    </div>
                    <div className="col-12 mt-2 mb-3 d-flex justify-content-start align-items-center flex-wrap gap-2">
                      {projectType?.find(obj => obj?.value == "1") && (
                        <div className="col-12 d-flex justify-content-between align-items-center gap-2">
                          <div className="col-11">
                            <label htmlFor="">SEO</label>
                            <ReactSelect
                              placeholder="Select SEO Package"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={seoPackageOption?.filter(
                                obj =>
                                  obj?.country == projectBelong?.value ||
                                  obj?.value == 0
                              )}
                              value={seoPackage}
                              onChange={e => {
                                setPackageService(1)
                                setSeoPackage(e)
                                if (e.value == 0) {
                                  setShowPopup(true)
                                }
                              }}
                            />
                          </div>
                          {seoPackage?.value == 0 && seoCustomPackageId != null ? (
                            <div className=" col-1 d-flex justify-content-center align-itmes-center">
                              <a
                                className="btn btn-sm btn-outline-info  mt-4"
                                title="View Package Details"
                                onClick={() => onGetCustomData(seoCustomPackageId, 1)}
                              >
                                <i
                                  className="bi bi-info-lg"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "2") && (
                        <div className="col-12 d-flex justify-content-between align-items-center gap-2">
                          <div className="col-11">
                            <label htmlFor="">SMM</label>
                            <ReactSelect
                              placeholder="Select SMM Package"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={ssmPackageOption?.filter(
                                obj =>
                                  obj?.country == projectBelong?.value ||
                                  obj?.value == 0
                              )}
                              value={smmPackage}
                              onChange={e => {
                                setPackageService(2)
                                setSmmPackage(e)
                                if (e.value == 0) {
                                  setShowPopup(true)
                                }
                              }}
                            />
                          </div>
                          {smmPackage?.value == 0 && smmCustomPackageId != null ? (
                            <div className=" col-1 d-flex justify-content-center align-itmes-center">
                              <a
                                className="btn btn-sm btn-outline-info  mt-4"
                                title="View Package Details"
                                onClick={() => onGetCustomData(smmCustomPackageId, 2)}
                              >
                                <i
                                  className="bi bi-info-lg"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "3") && (
                        <div className="col-12 d-flex justify-content-between align-items-center gap-2">
                          <div className="col-11">
                            <label htmlFor="">PPC</label>
                            <ReactSelect
                              placeholder="Select PPC Package"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={ppcPackageOption?.filter(
                                obj =>
                                  obj?.country == projectBelong?.value ||
                                  obj?.value == 0
                              )}
                              value={ppcPackage}
                              onChange={e => {
                                setPackageService(3)
                                setPpcPackage(e)
                                if (e.value == 0) {
                                  setShowPopup(true)
                                }
                              }}
                            />
                          </div>
                          {ppcPackage?.value == 0 && ppcCustomPackageId != null ? (
                            <div className=" col-1 d-flex justify-content-center align-itmes-center">
                              <a
                                className="btn btn-sm btn-outline-info  mt-4"
                                title="View Package Details"
                                onClick={() => onGetCustomData(ppcCustomPackageId, 3)}
                              >
                                <i
                                  className="bi bi-info-lg"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {projectType?.find(obj => obj?.value == "4") && (
                        <div className="col-12 d-flex justify-content-between align-items-center gap-2">
                          <div className="col-12">
                            <label htmlFor="">EM</label>
                            <ReactSelect
                              placeholder="Select EM Package"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={emPackageOption?.filter(
                                obj =>
                                  obj?.country == projectBelong?.value ||
                                  obj?.value == 0
                              )}
                              value={emPackage}
                              onChange={e => {
                                setPackageService(4)
                                setEmPackage(e)
                                if (e.value == 0) {
                                  setShowPopup(true)
                                }
                              }}
                            />
                          </div>
                          {emPackage?.value == 0 && emCustomPackageId != null ? (
                            <div className=" col-1 d-flex justify-content-center align-itmes-center">
                              <a
                                className="btn btn-sm btn-outline-info  mt-4"
                                title="View Package Details"
                                onClick={() => onGetCustomData(emCustomPackageId, 3)}
                              >
                                <i
                                  className="bi bi-info-lg"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </Row>
                </div>
              </Row>
              <div
                className={`note-sticky-form ${
                  isPopupOpen ? "d-block" : "d-none"
                }`}
              >
                <Draggable>
                  <ResizableBox
                    width={1000}
                    style={{
                      position: "fixed",
                      top: "16%",
                      right: "18%",
                      zIndex: "1055",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div className="modal-header pe-4">
                      <h5 className="modal-title mt-0">Custom Package</h5>
                    </div>

                    <div className="modal-body pe-4">
                      <Form className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={packageImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>

                        <div className="col-md-7 d-flex flex-column justify-space-between  gap-2">
                          <div className="col-12 d-flex flex-column  flex-wrap">
                            <label htmlFor="">
                              Activity <span className="text-danger">*</span>
                            </label>
                            <ReactSelect
                              placeholder="Select Activity"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isMulti
                              options={Activity_Options}
                              value={selectedActivity}
                              onChange={selectedOption =>
                                setSelectedActivity(selectedOption)
                              }
                            />
                          </div>
                          <div className="col-12 d-flex flex-row flex-wrap gap-3 mt-3 mb-0">
                            <div className="col-4 d-flex flex-column  flex-wrap">
                              <label htmlFor="">
                                Quantity <span className="text-danger"> *</span>
                              </label>
                              <ReactSelect
                                placeholder="Select Quantity"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                                options={quantity_Options}
                                value={selectedQuantity}
                                onChange={selectedOption =>
                                  setSelectedQuantity(selectedOption)
                                }
                              />
                            </div>

                            <div className="col-4 d-flex flex-column  flex-wrap">
                              <label htmlFor="">
                                Week <span className="text-danger"> *</span>
                              </label>
                              <ReactSelect
                                placeholder="Select Week"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                                options={week_Options}
                                // isMulti="true"
                                value={selectedWeek}
                                onChange={selectedOption =>
                                  setSelectedWeek(selectedOption)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="text-end">
                        <button className=" btn btn-save btn-label">
                          <i className="fas fa-save me-2 label-icon"></i>
                          Save
                        </button>
                        <a
                          onClick={() => setIsPopupOpen(false)}
                          className=" btn ms-md-2 btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </a>
                      </div>
                    </div>
                  </ResizableBox>
                </Draggable>
              </div>

              <div
                className={`note-sticky-form ${
                  showPopup ? "d-block" : "d-none"
                }`}
              >
                <Draggable>
                  <ResizableBox
                    width={boxWidth}
                    height={boxHeight}
                    style={{
                      position: "fixed",
                      top: "7%",
                      left: "15%",
                      zIndex: "1055",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div className="modal-header pe-0">
                      <h5 className="modal-title mt-0">Custom Package</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setShowPopup(false)
                        }}
                        className="btn-modal-close"
                      >
                        <span aria-hidden="true" className="btn-modal-span">
                          &times;
                        </span>
                      </button>
                    </div>

                    <div className="modal-body pe-3">
                      <Form className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={packageImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>

                        {/* Main Row  */}
                        <div className="col-md-7 d-flex flex-column justify-space-between gap-2 viewPackage-box">
                          <div className="d-flex flex-column">
                            <div className="contact-form d-flex col-md-12 p-2 outline-secondary">
                              <div className="contact-column edit-contact-column mb-md-0 col-9 ps-1">
                                <label htmlFor="">Activity Name</label>
                              </div>
                              <div className="contact-column edit-contact-column mb-md-0 col-2 ps-1">
                                <label htmlFor="">Quantity</label>
                              </div>
                              <div className="col-1 contact-btns ml-10 d-flex justify-content-end align-items-end gap-2 mb-md-0">
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onAddFormRow()
                                  }}
                                  className="btn btn-purple  "
                                >
                                  <i className="fas fa-plus me-0"></i>
                                </button>
                              </div>
                            </div>

                            {formRows?.map((person, key) => {
                              return (
                                <div key={key} className="">
                                  <div className="contact-form d-flex col-md-12 p-2 outline-secondary">
                                    <div className="contact-column edit-contact-column mb-md-0 col-9">
                                      <ReactSelect
                                        placeholder="Select Activity"
                                        className="basic-multi-select "
                                        classNamePrefix="select"
                                        options={allActivities?.filter(
                                          obj => obj?.service == packageService
                                        )}
                                        value={person?.activity}
                                        onChange={e =>
                                          handleSelectChange(e, key)
                                        }
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: base => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                      />
                                    </div>
                                    <div className="contact-column edit-contact-column mb-md-0 col-2">
                                      <input
                                        type="text"
                                        name="task_name"
                                        placeholder="Quantity"
                                        className="form-control "
                                        value={person?.quantity}
                                        onChange={e =>
                                          handleQuantityChange(e, key)
                                        }
                                      />
                                    </div>
                                    <div className="col-1 contact-btns ml-10   d-flex justify-content-end align-items-end gap-2 mb-md-0">
                                      <button
                                        onClick={e => {
                                          e.preventDefault()
                                          onDeleteFormRow(key)
                                        }}
                                        className="btn btn-red"
                                      >
                                        <i className="fas fa-trash-alt me-0"></i>
                                      </button>
                                    </div>
                                  </div>

                                  {key !== formRows.length - 1 ? (
                                    <div className=""></div>
                                  ) : null}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="text-end my-3 me-0 pe-0">
                      <button
                        className=" btn btn-save btn-label"
                        type="button"
                        onClick={onAddCustomPackage}
                      >
                        <i className="fas fa-save me-2 label-icon"></i>
                        Save
                      </button>
                      <a
                        onClick={() => setShowPopup(false)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </ResizableBox>
                </Draggable>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DMBasicProjectDetails
