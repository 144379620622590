import React from "react"
import indiaFlag from "../../../assets/images/flags/india.png"
import ukFlag from "../../../assets/images/flags/uk.png"
import { DigitalServiceOptionsDrop } from "utils/common-options"
export const settingsColumns = (onDeleteActivity, onEditActivity) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "activity_id_width",
    },
    {
        Header: "Activity Name",
        accessor: "activity",
        isShortIcon: true,
        className: "activity_name",
        Cell: cellProps => {
            const row = cellProps.row?.original
            const service = DigitalServiceOptionsDrop.find(obj => obj.value == row.service)
            return (
                <div>
                    <a className={` ${service?.label}-color me-2`}>
                        <i className={`${service?.icon} ${service?.label}-color fs-5` }></i>
                    </a>
                    {row?.activity}
                </div>
            )
        },
    },
    {
        Header: "Est. Time",
        accessor: "est_time",
        isShortIcon: true,
        className: "estimate_time",

    },
    {
        Header: "Action",
        accessor: "action",
        className: "activity_action_width",
        Cell: cellProps => {
            return (
                <div>
                    <a
                        onClick={() => {
                            onEditActivity(cellProps.row.original.id, cellProps.row.original)
                        }}
                        className="btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a onClick={() => onDeleteActivity(cellProps.row.original.id)} className="btn btn-sm  btn-danger">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]

export const packageColumns = (onDeletePackage, editPackage) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "package_id_width",
    },
    {
        Header: "Package Name",
        accessor: "name",
        isShortIcon: true,
        className: "package_name",
        Cell: cellProps => {
            const location = cellProps?.row?.original?.country
            return (
                <div>
                    <img
                        src={location == "1" ? indiaFlag : ukFlag}
                        value={location}
                        className="location-img me-3"
                        alt="flag"
                    />
                    {cellProps?.row?.original?.name}
                </div>
            )
        },
    },
    {
        Header: "On Page No.",
        accessor: "on_page_count",
        isShortIcon: true,
        className: "activity_count",
    },
    {
        Header: "Off Page No.",
        accessor: "off_page_count",
        isShortIcon: true,
        className: "activity_count",
    },
    {
        Header: "On Page Hrs",
        accessor: "on_page_est_time",
        isShortIcon: true,
        className: "estimated_hourse",
    },
    {
        Header: "Off Page Hrs",
        accessor: "off_page_est_time",
        isShortIcon: true,
        className: "estimated_hourse",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "action_width",
        Cell: cellProps => {
            return (
                <div>
                    <a
                        onClick={() => {
                            editPackage(cellProps?.row?.original)
                        }}
                        className="btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a onClick={() => onDeletePackage(cellProps?.row?.original?.id)} className="btn btn-sm  btn-danger">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]