import React, { useMemo, useState, useEffect } from "react"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { useNavigate } from "react-router-dom"
import Select from "react-select";
import NewPagination from "components/Common/Newpagination"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import AddBankImg from "../../../../assets/images/settingImg/addBankImg.jpg"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import { overDraftBanksColumns } from "utils/columns/Finance/financeColumns"

const OverdraftCategory = () => {

  const [fixedContent, setFixedContent] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const navigate = useNavigate()
  const [fixedSideImg, setFixedSideImg] = useState(false)
  const [addBankLoan, setAddBankLoan] = useState(false)
  const [editBankLoan, setEditBankLoan] = useState(false)
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState([])
  const [bank_name, setBankName] = useState('')
  const [acc_no, setAccountNo] = useState('')
  const [avl_limit, setAvlLimit] = useState('')
  const [interest, setInterest] = useState('')
  const [bank_id, setBankId] = useState('')
  const [countryOption, setCountryOption] = useState([])
  const [allData, setData] = useState([])

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  document.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setFixedSideImg(true)
    } else {
      setFixedSideImg(false)
    }
  })
  const token = secureLocalStorage.getItem('auth_token')
  const toggleLoading = (loading) => {
    setLoading(loading)
  }


  const fetchODs = async () => {
    const res = await fetchData(toggleLoading, 'overdraftBanks', token);
    if (res?.success) {
      setData(res?.data?.banks)
      setCountryOption(res?.data?.location?.map((obj) => ({
        label:obj?.location,
        value: obj?.id,
      })))
    }

  }

  useEffect(() => {
    fetchODs()
  }, [])


  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Overdraft!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetchData(toggleLoading, `deleteOverdraftBanks/${id}`, token);
        if (res?.success) {
          Swal.fire({
            icon: "success",
            text: "Overdraft Deleted Successfully",
          }).then(() => {
            console.log(res)
            setData(res?.data?.banks)
          })
        }
      }
    });
  };

  const onEditDraft = (obj) => {
    setBankId(obj?.id);
    setBankName(obj?.bank_name)
    setInterest(obj?.interest_rate)
    setAccountNo(obj?.account_no)
    setAvlLimit(obj?.credit_limit)
    setLocation(countryOption?.filter((loc) => loc?.value == obj?.location))
    setEditBankLoan(true)
  }

  const columns = useMemo(
    () => overDraftBanksColumns(onEditDraft, confirmDelete),
    [countryOption]
  )


  const data = useMemo(() => allData, [allData])

  useEffect(() => {
    setPageSize(50)
  }, [])


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const onAddOverDraft = async (e) => {
    e.preventDefault();
    const formEntries = {
      location: location?.value,
      bank_name: bank_name,
      account_no: acc_no,
      interest_rate: interest,
      credit_limit: avl_limit
    }
    const res = await FetchPostData(toggleLoading, 'addOverdraftBanks', token, formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Overdraft Added Successfully",
      }).then(() => {
        setBankName('')
        setAccountNo('')
        setAvlLimit('')
        setLocation([])
        setInterest('')
        setAddBankLoan(false)
        setData(res?.data?.data?.banks)
      })
    }
  }

  const onEditOverDraft = async (e) => {
    e.preventDefault();
    const formEntries = {
      bank_id: bank_id,
      location: location?.value,
      bank_name: bank_name,
      account_no: acc_no,
      interest_rate: interest,
      credit_limit: avl_limit
    }
    const res = await FetchPostData(toggleLoading, 'editOverdraftBanks', token, formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Overdraft Edited Successfully",
      }).then(() => {
        setBankName('')
        setAccountNo('')
        setAvlLimit('')
        setLocation([])
        setInterest('')
        setEditBankLoan(false)
        setData(res?.data?.data?.banks)
      })
    }
  }



  return (
    <>
      {loading && (<Loader />)}
      <div>
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>

                <div>
                  <Row className="mb-2">
                    <Col>
                      <select
                        className="border-radius form-select"
                        style={{ width: "100px" }}
                        value={pageSize}
                        onChange={e => setPageSize(e.target.value)}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={8} className="d-flex justify-content-end">
                      <div className="me-4">
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => setGlobalFilter(e.target.value)}
                                id="search-bar-0"
                                type="text"
                                className="border-radius form-control rounded h-100"
                                placeholder={` Search...`}
                                value={globalFilter || ""}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          onClick={() => setAddBankLoan(true)}
                          className="btn btn-purple btn-label"
                        >
                          <i className="fas fa-plus me-2 icon-size label-icon"></i>
                          Overdraft
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          className="btn ms-md-2 btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className={`${fixedSideImg ? "departImg" : ""}`}>
                        <img
                          src={AddBankImg}
                          alt="departmentImg"
                          className="w-100"
                        />
                      </div>
                    </div>

                    <div className="col-md-8 pt-4">
                      <Table
                        className="table table-bordered mt-4"
                        {...getTableProps()}
                      >
                        <thead
                          className={`${fixedContent && page.length >= 10
                            ? "fixed-s-setting-header"
                            : ""
                            }`}
                        >
                          {headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, index) => (
                                <th
                                  key={index}
                                  className={`${column.className} customShortIcon`}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}

                                  {column.isShortIcon && (
                                    <span
                                      onClick={() =>
                                        column.getSortByToggleProps().onClick()
                                      }
                                      className="ms-2 cursor-pointer"
                                    >
                                      <i
                                        title="Ascending"
                                        className={`bi bi-arrow-up info-icon ${column.isSorted &&
                                          !column.isSortedDesc
                                          ? "text-dark"
                                          : ""
                                          }`}
                                      ></i>
                                      <i
                                        title="Descending"
                                        className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                          ? "text-dark"
                                          : ""
                                          }`}
                                      ></i>
                                    </span>
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row)
                            return (
                              <tr
                                className="row-hover"
                                {...row.getRowProps()}
                                key={i}
                              >
                                {row.cells.map((cell, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className={`t-col-${index + 1} ${cell.column.className
                                        }`}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                          {page.length == 0 && (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No Data Found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                  {/* Pagination */}
                  <Row className="justify-content-md-end justify-content-center align-items-center">
                    <NewPagination
                      gotoPage={gotoPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      pageSize={pageSize}
                      page={page}
                      data={data}
                    />
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={addBankLoan}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Overdraft</h5>
          <button
            type="button"
            onClick={() => setAddBankLoan(false)}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddOverDraft}>
            <Row>
              <div className="col-5">
                <img
                  src={AddBankImg}
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="col-7 d-flex flex-column align-items-start justify-content-center">
                <div className="mb-4 col-4">
                  <label htmlFor="formrow-firstname-Input">Country</label>
                  <Select
                    placeholder="Select Country"
                    className="basic-multi-select border-radius"
                    classNamePrefix="select"
                    options={countryOption}
                    value={location}
                    onChange={(e) => setLocation(e)}
                  />
                </div>
                <div className="col-12 d-flex align-items-start justify-content-start gap-4 mb-4">
                  <div className="col-8 d-flex flex-column align-items-start justify-content-center ">
                    <label htmlFor="">
                      Bank Name
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name"
                      className="form-control "
                      required
                      value={bank_name}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column align-items-start justify-content-center ">
                    <label htmlFor="">
                      Rate Of Interest
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Interest"
                      className="form-control "
                      required
                      value={interest}
                      onChange={(e) => setInterest(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex align-items-start justify-content-start gap-4">
                  <div className="col-8 d-flex flex-column align-items-start justify-content-start ">
                    <label htmlFor="">
                      OD Account Number
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter OD Account Number"
                      className="form-control "
                      required
                      value={acc_no}
                      onChange={(e) => setAccountNo(e.target.value)}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column align-items-start justify-content-start ">
                    <label htmlFor="">
                      Available Limit
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Avl. Limit"
                      className="form-control "
                      required
                      value={avl_limit}
                      onChange={(e) => setAvlLimit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <a
                onClick={() => setAddBankLoan(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      {/* Edit  Modal */}
      <Modal
        isOpen={editBankLoan}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Overdraft</h5>
          <button
            type="button"
            onClick={() => {
              setEditBankLoan(false)
              setBankName('')
              setAccountNo('')
              setLocation([])
              setInterest('')
              setAvlLimit('')
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditOverDraft}>
            <Row>
              <div className="col-5">
                <img
                  src={AddBankImg}
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="col-7 d-flex flex-column align-items-start justify-content-center gap-4">
                <div className="col-5">
                  <label htmlFor="formrow-firstname-Input">Country</label>
                  <Select
                    placeholder="Select Country"
                    className="basic-multi-select border-radius"
                    classNamePrefix="select"
                    options={countryOption}
                    value={location}
                    onChange={(e) => setLocation(e)}
                  />
                </div>
                <div className="col-12 d-flex align-items-start justify-content-start gap-4">
                  <div className="col-8 d-flex flex-column align-items-start justify-content-center ">
                    <label htmlFor="">
                      Bank Name
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name"
                      className="form-control "
                      required
                      value={bank_name}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column align-items-start justify-content-center ">
                    <label htmlFor="">
                      Rate Of Interest
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Interest"
                      className="form-control "
                      required
                      value={interest}
                      onChange={(e) => setInterest(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex align-items-start justify-content-start gap-4">
                  <div className="col-8 d-flex flex-column align-items-start justify-content-start ">
                    <label htmlFor="">
                      Account Number
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Account Number"
                      className="form-control "
                      required
                      value={acc_no}
                      onChange={(e) => setAccountNo(e.target.value)}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column align-items-start justify-content-start ">
                    <label htmlFor="">
                      Max. Limit
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter  Max. Limit"
                      className="form-control "
                      required
                      value={avl_limit}
                      onChange={(e) => setAvlLimit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <a
                onClick={() => {
                  setEditBankLoan(false)
                  setBankName('')
                  setAccountNo('')
                  setLocation([])
                  setInterest('')
                  setAvlLimit('')
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}


      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </>
  )
}

export default OverdraftCategory
