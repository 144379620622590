import React, { useEffect, useState } from "react"
import {
  Table,
  Row,
  Col,
  Input,
  Modal,
  Form,
  Label,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import Select from "react-select"
import packageImg from "../../../assets/images/department.jpg"
import ReactSelect from "react-select"
import NewPagination from "components/Common/Newpagination"
import { packageColumns } from "utils/columns/Digital Marketing/settingColumns"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"

function TableContainer({ columns, datas, activityOption, country, verticalActiveTab, updateData }) {
  const [status, setStatus] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [formRows, setFormRows] = useState([{ activity: null, quantity: '' }])
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const toggleLoading = (load) => {
    setLoading(load)
  }

  useEffect(() => {
    setData(datas)
  }, [datas])

  const onAddFormRow = () => {
    setFormRows([...formRows, { activity: null, quantity: '' }]);
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const handleSelectChange = (selectedOption, index) => {
    const newFormRows = [...formRows];
    newFormRows[index].activity = selectedOption;
    setFormRows(newFormRows);
  };

  const handleQuantityChange = (e, index) => {
    const newFormRows = [...formRows];
    newFormRows[index].quantity = e.target.value;
    setFormRows(newFormRows);
  };

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  useEffect(() => {
    setPageSize(50)
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const navigate = useNavigate();
  const [location, setLocation] = useState([]);
  const [packageName, setPackageName] = useState('')

  const onAddPackage = async (e, index) => {
    e.preventDefault();
    if(location?.length != 0 ){
    const token = secureLocalStorage?.getItem('auth_token')
    const formEntries = {
      user_id: localStorage.getItem('auth_id'),
      country: location?.value,
      name: packageName,
      activity_id: formRows?.map((obj) => obj?.activity?.value)?.join(','),
      quantity: formRows?.map((obj) => obj?.quantity)?.join(','),
      service: verticalActiveTab
    }
    const response = await FetchPostData(toggleLoading, "addDigitalPackages", token, formEntries);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Package Added Successfully",
      }).then(() => {
        setData(response?.data?.data?.packages?.filter((obj) => obj?.service == verticalActiveTab))
        updateData(response?.data?.data?.packages)
        setStatus(false)
        setPackageName('')
        setLocation(null);
        setFormRows([{}])
      })
    }
    }else{
      Swal.fire({
        icon: "warning",
        text: "Please fill all required fields",
      })
    }
  }


  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <button
          onClick={() => {
            setStatus(true)
          }}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2"></i>
          <span>Package</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={2}>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className="border-radius btn btn-purple"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Package
            </a>
            <a
              className="border-radius btn text-white ms-md-2 btn-red"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center"></i>Cancel
            </a>
          </div>
        </Col>
      </Row>

      <Table className="table table-bordered" {...getTableProps()}>
        <thead
          className={`${fixedContent && page.length >= 5 ? "fixed-package-header" : "fix-table-header"
            } `}
        >
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                const classNames = column.className
                return (
                  <th
                    key={index}
                    className={`col-t-${index + 1} ${classNames} ${index != 0 && "customShortIcon"}`}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps()
                    )}

                  >
                    <div>
                      {column.render("Header")}

                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${column.isSorted &&
                              !column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                        </span>
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        <tbody className="fix-support-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            let backgroundStyle = {}
            const priority = row.original.priority_col
            return (
              <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    className={`t-col-${index + 1} ${cell.column.className} ${priority == "High" ? "highPriority" : ""
                      }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {data?.length == 0 ? (
        <p className="text-center text-purple fw-bold">🔍 Hmm... couldn't find any data! Time to add something amazing</p>
      ) : null}

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>

      <Modal
        isOpen={status}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        {isLoading && <Loader />}
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Package</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddPackage}>
            {/* <Form> */}
            <Row>
              <div className="col-5">
                <img src={packageImg} className="img-fluid" />
              </div>
              <div className="col-7 d-flex align-items-center justify-content-center flex-column gap-2">
                <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                  <div className="mb-3 col-4">
                    <Label htmlFor="formrow-firstname-Input">Country <span className="text-danger"> *</span></Label>
                    <Select
                      placeholder="Select Country"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      options={country}
                      value={location}
                      onChange={(e) => setLocation(e)}
                    />
                  </div>
                  <div className="mb-3 col-8">
                    <Label htmlFor="formrow-firstname-Input">Package Name <span className="text-danger"> *</span> </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Package Name"
                      value={packageName}
                      onChange={(e) => setPackageName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="contact-form d-flex flex-column col-md-12 p-2 outline-secondary setting_package_box">

                  {formRows?.map((person, key) => {
                    return (
                      <div key={key} className="">
                        <div className="col-12 d-flex justify-content-center align-items-center my-2">
                          <div className="contact-column edit-contact-column mb-md-0 col-9">
                            <ReactSelect
                              placeholder="Select Activity"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={activityOption?.filter((obj) => obj?.service == verticalActiveTab)}
                              value={person.activity}
                              onChange={(selectedOption) => handleSelectChange(selectedOption, key)}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999, // Ensure dropdown is on top
                                }),
                              }}
                            />
                          </div>
                          <div className="contact-column edit-contact-column mb-md-0 col-2">
                            <input
                              type="text"
                              name="task_name"
                              placeholder="Enter Qty"
                              className="form-control "
                              value={person.quantity}
                              onChange={(e) => handleQuantityChange(e, key)}
                            />
                          </div>
                          <div className="col-1 contact-btns d-flex justify-content-end align-items-end mb-md-0 mt-0">
                            {key == 0 ? (
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  onAddFormRow()
                                }}
                                className="btn btn-purple  "
                              >
                                <i className="fas fa-plus me-0"></i>
                              </button>
                            ) : (
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  onDeleteFormRow(key)
                                }}
                                className="btn btn-red"
                              >
                                <i className="fas fa-trash-alt me-0"></i>
                              </button>
                            )}
                          </div>
                        </div>
                        {key !== formRows.length - 1 ? (
                          <div className=""></div>
                        ) : null}
                      </div>
                    )
                  })}

                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"
              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setStatus(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const PackageDm = ({ activity, packages, country, verticalActiveTab, updateData }) => {
  const [activityOption, setActivityOption] = useState([])
  const [loading, setLoading] = useState(false)
  const [edit_status, setEditStatus] = useState(false)
  const [data, setData] = useState([])
  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  useEffect(() => {
    setActivityOption(activity?.map((obj) => ({
      label: obj.activity,
      value: obj.id,
      service:obj?.service
    })))
    setData(packages?.filter((obj) => obj?.service == verticalActiveTab))
  }, [activity, packages, verticalActiveTab])

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const [formRows, setFormRows] = useState([{}])

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }


  const [location, setLocation] = useState(null);
  const [packageName, setPackageName] = useState('')
  const [packageId, setPackageId] = useState('')

  const editPackage = (obj) => {
    setPackageId(obj?.id);
    setPackageName(obj?.name)
    setLocation(country?.find((cty) => cty.value == obj?.country))
    setEditStatus(true);
    const sub = obj?.activity_id?.split(",");
    const qty = obj?.quantity?.split(",");
    setFormRows(
      sub?.map((obj, i) => ({
        activity: activityOption?.find((act) => act?.value == Number(obj)),
        quantity: qty[i]
      }))
    )
  }


  const handleSelectChange = (selectedOption, index) => {
    const newFormRows = [...formRows];
    newFormRows[index].activity = selectedOption;
    setFormRows(newFormRows);
  };

  const handleQuantityChange = (e, index) => {
    const newFormRows = [...formRows];
    newFormRows[index].quantity = e.target.value;
    setFormRows(newFormRows);
  };

  const onDeletePackage = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this Package!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetchData(toggleLoading, `deleteDigitalPackages/${id}`, secureLocalStorage.getItem('auth_token'))
        if (response?.success) {
          Swal.fire({
            icon: "success",
            text: "Package Deleted Successfully",
          })
          setData(response?.data?.packages?.filter((obj) => obj?.service == verticalActiveTab))
          updateData(response?.data?.packages)
        }
      }
    })
  }

  const onEditPackage = async (e) => {
    e.preventDefault();
    const token = secureLocalStorage?.getItem('auth_token')
    const formEntries = {
      user_id: localStorage.getItem('auth_id'),
      package_id: packageId,
      country: location?.value,
      name: packageName,
      activity_id: formRows?.map((obj) => obj?.activity?.value)?.join(','),
      quantity: formRows?.map((obj) => obj?.quantity)?.join(','),
      service: verticalActiveTab
    }
    const response = await FetchPostData(toggleLoading, "editDigitalPackages", token, formEntries);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Package Edited Successfully",
      }).then(() => {
        setData(response?.data?.data?.packages?.filter((obj) => obj?.service == verticalActiveTab))
        updateData(response?.data?.data.packages)
        setEditStatus(false)
        setPackageName('')
        setLocation(null);
        setFormRows([{}])
      })
    }
  }
  const columns = useMemo(
    () => packageColumns(onDeletePackage, editPackage),
    [activityOption]
  )


  return (
    <div>
      {loading && <Loader />}
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table z-2">
            <TableContainer columns={columns} datas={data} activityOption={activityOption} country={country} verticalActiveTab={verticalActiveTab} updateData={updateData} />
          </div>
        </Col>

        <Modal
          isOpen={edit_status}
          toggle={() => {
            tog_center()
          }}
          centered
          size="xl"
        >
          {loading && <Loader />}
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Package</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onEditPackage}>
              <Row>
                <div className="col-5">
                  <img src={packageImg} className="img-fluid" />
                </div>
                <div className="col-7 d-flex align-items-center justify-content-center flex-column gap-2">
                  <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                    <div className="mb-3 col-4">
                      <Label htmlFor="formrow-firstname-Input">Country</Label>
                      <Select
                        placeholder="Select Country"
                        className="basic-multi-select border-radius"
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={country}
                        value={location}
                        onChange={(e) => setLocation(e)}
                      />
                    </div>
                    <div className="mb-3 col-8">
                      <Label htmlFor="formrow-firstname-Input">Package Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Package Name"
                        value={packageName}
                        onChange={(e) => setPackageName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="contact-form d-flex flex-column col-md-12 p-2 outline-secondary setting_package_box">

                    {formRows?.map((person, key) => {
                      return (
                        <div key={key} className="">
                          <div className="col-12 d-flex justify-content-center align-items-center my-2">
                            <div className="contact-column edit-contact-column mb-md-0 col-9">
                              <ReactSelect
                                placeholder="Select Activity"
                                className="basic-multi-select "
                                classNamePrefix="select"
                                options={activityOption?.filter((obj) => obj?.service == verticalActiveTab)}
                                value={person?.activity}
                                onChange={(e) => handleSelectChange(e, key)}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                            <div className="contact-column edit-contact-column mb-md-0 col-2">
                              <input
                                type="text"
                                name="task_name"
                                placeholder="Enter Qty"
                                className="form-control"
                                value={person?.quantity}
                                onChange={(e) => handleQuantityChange(e, key)}
                              />
                            </div>
                            <div className="col-1 contact-btns d-flex justify-content-end align-items-end mb-md-0 mt-0">
                              {key == 0 ? (
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onAddFormRow()
                                  }}
                                  className="btn btn-purple  "
                                >
                                  <i className="fas fa-plus me-0"></i>
                                </button>
                              ) : (
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onDeleteFormRow(key)
                                  }}
                                  className="btn btn-red"
                                >
                                  <i className="fas fa-trash-alt me-0"></i>
                                </button>
                              )}
                            </div>
                          </div>

                          {key !== formRows.length - 1 ? (
                            <div className=""></div>
                          ) : null}
                        </div>
                      )
                    })}

                  </div>
                </div>
              </Row>
              <div className="border-bottom my-3"></div>
              <div className="text-end">
                <button className=" btn btn-purple btn-label"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setEditStatus(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default PackageDm
