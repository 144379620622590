import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import { filterNotificationData, getModuleicon } from "utils/common-helpers"
import Select from "react-select"
import { ModuleOptions } from "utils/common-options"


const Notification = () => {
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [moduleId, setModuleId] = useState([])

    const toggleLoading = loading => {
        setLoading(loading)
    }
    useEffect(() => {
        const getData = async () => {
            const user = localStorage.getItem('auth_id')
            const res = await fetchData(toggleLoading, `notifications/${user}`, '')
            if (res?.success) {
                setData(res?.data)
                setAllData(res?.data)
            }
        }
        if (data?.length == 0) {
            getData()
        }
    }, [])

    const onSearchNotification = (e) => {
        e.preventDefault()
        const searchTerm = e.target.value.toLowerCase()
        const filteredData = allData.filter(obj => obj.title.toLowerCase().includes(searchTerm) || obj.message.toLowerCase().includes(searchTerm) || obj.module.toLowerCase().includes(searchTerm) || obj.id == searchTerm)
        setData(filteredData)
    }
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div>
                <Card>
                    <CardBody>
                        <div className="mb-4 d-flex justify-content-between">
                            <div className="d-flex gap-2 col-md-4 align-items-center">
                                <p className="text-purple fs-5 mb-0 fw-bold col-md-4">Your Notification</p>
                                <input id="search-bar-0" type="text" className="border-radius form-control rounded h-100"
                                    placeholder="Search..." onChange={(e) => onSearchNotification(e)} />
                            </div>
                            <div className="d-flex gap-2 col-md-6 align-items-center">
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                                <Select
                                    placeholder="Select Module"
                                    className="basic-multi-select col-md-3"
                                    classNamePrefix=" select"
                                    options={ModuleOptions}
                                    value={moduleId}
                                    onChange={(e) => setModuleId(e)}
                                />
                                <button className="btn btn-sm  me-1 bg-purple text-white " onClick={(e) => {
                                    e.preventDefault();
                                    setData(filterNotificationData(allData, fromDate, toDate, moduleId))
                                }}>
                                    <i className="bi bi-funnel-fill fs-6"></i>
                                </button>
                                <button className="btn btn-sm  me-1 bg-warning  text-white " onClick={(e) => {
                                    e.preventDefault();
                                    setFromDate('')
                                    setToDate('')
                                    setModuleId([])
                                    setData(allData)
                                }}>
                                    <i className="bi bi-x fs-6"></i>
                                </button>

                            </div>
                        </div>
                        <div className="responsive-table mb-4" >
                            <Table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-center header">Id</th>
                                        <th className="text-center header col-2">Time</th>
                                        <th className="header col-10">Notification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((obj, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td className="text-center">
                                                    {moment(obj?.created_at).format('DD-MM-YYYY hh:mm:ss a')}
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-start gap-1 notification_box">
                                                        <div className="module-icon me-2">{getModuleicon((obj?.module)?.toLowerCase())}</div>
                                                        <div>
                                                            {obj.title}
                                                            <div className="ms-4">
                                                                <i className="bx bx-subdirectory-right fs-5"></i>{" "}
                                                                {obj.message}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            {data?.length == 0 ? (
                                <p className="text-center text-purple fw-bold mt-3">
                                    🎉 All Quiet for Now 🚀
                                </p>
                            ) : null}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default Notification
