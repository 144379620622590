import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import "../../../assets/css/Scollbar.css"
import "../../../assets/css/Work/projects.css"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import classNames from "classnames"
import ApprovalDmStep from "./Planning Steps/ApprovalDmStep"
import CompleteDmStep from "./Planning Steps/CompleteDmStep"
import FinalPlanDays from "./FinalPlanDays"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import DmProjectPlanIndex from "./DmProjectPlanIndex"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import { fetchData, FetchPostData } from "utils/common-fetchData"


const StepNavigationStep = ({ step, currentStep, onStepChange }) => {
  const classes = classNames({
    active: step.id === currentStep,
    complete: currentStep > step.id,
  });

  return (
    <li className={classes}
      onClick={() => {
        if (currentStep >= step.id) {
          onStepChange(step.id)
        }
      }}
    >
      <div className="step dm-planning-step">
        <i className={step.icon_class}></i>
      </div>
      <div className="caption hidden-xs hidden-sm">
        <span className="fs-5">{step.title}</span>
      </div>
    </li>
  );
};

const onChangingStep = async (step, toggleLoading, type, project_id) => {
  const formEntries = {
    type: type,
    project_id: project_id,
    stage: step
  }
  const response = await FetchPostData(
    toggleLoading,
    "updatePlanStages",
    secureLocalStorage?.getItem("auth_token"),
    formEntries
  )
  return response?.data?.success
}


const StepNavigation = ({ steps, currentStep, onStepChange }) => {
  return (
    <ol className="step-indicator dm-planning pt-0 mb-0">
      {steps.map((step, i) => (
        <StepNavigationStep
          key={i}
          step={step}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
      ))}
    </ol>
  );
};


const MainDmProjectPlan = () => {
  document.title = "Digital Marketing | Zithas Crm"
  const [currentStep, setCurrentStep] = useState(1)
  const steps = [
    { id: 1, title: "Initial Plan Days", icon_class: "bi bi-1-circle-fill" },
    { id: 2, title: "Approval Process", icon_class: "bi bi-2-circle-fill" },
    { id: 3, title: "Final Plan Days", icon_class: "bi bi-3-circle-fill" },
    { id: 4, title: "Complete", icon_class: "bi bi-4-circle-fill" },
  ]
  const [resource, setResource] = useState('')
  const stepChanged = step => {
    setCurrentStep(step)
  }

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [detail, setDetails] = useState([])
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem('auth_token');
  const toggleLoading = (load) => {
    setLoading(load)
  }
  const onSendForApproval = async () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "you want to send for approval ?",
      showCancelButton: true,
    }).then(async result => {
      if (result?.isConfirmed) {
        const res = await fetchData(toggleLoading, `sendForApprove/${params?.id}/${params.seo}`, token)
        if (res?.success) {
          Swal.fire({
            icon: "success",
            title: "Digital Project Sent for Approval",
          }).then(() => {
            navigate(`${configure.appUrl}initial-project-plan/digital-marketing`)
          })
        }
      }
    })
  }

  const onLetsApprove = async () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "you want roll out the project?",
      showCancelButton: true,
    }).then(async result => {
      if (result?.isConfirmed) {
        const res = await fetchData(toggleLoading, `letsRoll/${params?.id}/${params.seo}`, token)
        if (res?.success) {
          Swal.fire({
            icon: "success",
            text: "Digital Project Rolled Out Successfully",
          }).then(() => {
            navigate(`${configure.appUrl}initial-project-plan/digital-marketing`)
          })
        }
      }
    })
  }
  const [stepOne, setStepOne] = useState('')
  const [stepTwo, setStepTwo] = useState('')
  const [stepThree, setStepThree] = useState('')
  const [approveBtn, setApproveBtn] = useState(false)

  const activitesLengths = (length) => {
    setStepOne(length)
  }
  const approvalList = (pending) => {
    setStepTwo(pending)
  }
  const finalList = (pending) => {
    setStepThree(pending)
  }

  const onChangeStep = async (length) => {
    if (currentStep == 1) {
      if (stepOne == "0") {
        const res = await onChangingStep(1, toggleLoading, params?.seo, params?.id)
        if (res) {
          setCurrentStep(2)
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "⚠️ Oops! Activities Pending!",
          text: "❗ Please plan the remaining activities before moving to the next step.",
        })
      }
    } else if (currentStep == 2) {
      if (stepTwo == "0") {
        const res = await onChangingStep(2, toggleLoading, params?.seo, params?.id)
        if (res) {
          setCurrentStep(3)
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "⚠️ Missing Developer Time!",
          text: " ❗ Please add the estimated time for the developers before moving ahead.",
        })
      }
    } else if (currentStep == 3) {
      if (stepThree == "0") {
        const res = await onChangingStep(3, toggleLoading, params?.seo, params?.id)
        if (res) {
          setCurrentStep(4)
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "⚠️ Oops! Activities Pending!",
          text: "❗ Please plan the remaining activities before moving to the next step.",
        })
      }
    }
  }


  useEffect(() => {
    if (location?.hash == "#rollout") {
      setCurrentStep(4)
    } else if (location?.hash == "#approve") {
      setCurrentStep(2)
    } else if (location?.hash == "#final") {
      setCurrentStep(3)
    }

    const getDetails = async () => {
      const res = await fetchData(toggleLoading, `digitalProjectData/${params?.id}`, token);
      if (res?.success) {
        const data = res?.data[0];
        setDetails(res?.data[0] || {})
        if (params?.seo == 1) {
          if (data?.seo_approve == null) {
            setApproveBtn(true)
          } else {
            setApproveBtn(false)
          }
        } else if (params?.seo == 2) {
          if (data?.smm_approve == null) {
            setApproveBtn(true)
          } else {
            setApproveBtn(false)
          }
        } else if (params?.seo == 3) {
          if (data?.ppc_approve == null) {
            setApproveBtn(true)
          } else {
            setApproveBtn(false)
          }
        } else if (params?.seo == 4) {
          if (data?.em_approve == null) {
            setApproveBtn(true)
          } else {
            setApproveBtn(false)
          }
        }

      }
    }
    getDetails()
  }, [])





  return (
    <React.Fragment>
      {isLoading && (<Loader />)}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Project Plan" breadcrumbItem={detail?.project_name} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <form action="">
                    <div className='d-flex justify-content-between align-items-center wizard'>
                      <div className=" col-7">
                        <StepNavigation steps={steps} currentStep={currentStep} onStepChange={stepChanged} />
                      </div>

                      <div className='col-5 d-flex gap-2 justify-content-end align-items-center'>

                        {currentStep == 4 ? (
                          <>
                            {localStorage?.getItem('auth_id') == 32 || localStorage?.getItem('auth_id') == 1 || localStorage?.getItem('auth_id') == 4 ? (
                              <button
                                type="button"
                                className="btn btn-save border-radius "
                                onClick={onLetsApprove}
                              >
                                Let's Roll
                              </button>
                            ) : null}
                          </>
                        ) : null}
                        {approveBtn &&
                          (secureLocalStorage.getItem("auth_role") == 9 ||
                            secureLocalStorage.getItem("auth_role") == 10 ||
                            secureLocalStorage.getItem("auth_role") == 22 ||
                            secureLocalStorage.getItem("auth_role") == 4 ||
                            secureLocalStorage.getItem("auth_role") == 11 ||
                            secureLocalStorage.getItem("auth_role") == 1 ||
                            secureLocalStorage.getItem("auth_role") == 18) &&
                          (
                            <>
                              {currentStep == 4 ? (
                                <button
                                  type="button"
                                  className="btn  border-radius bg-success text-white"
                                  onClick={onSendForApproval}
                                >
                                  Send for Approval
                                </button>
                              ) : null}
                            </>
                          )}


                        {
                          (currentStep === 1 || currentStep === 2 || currentStep === 3) && (
                            <a className=" btn text-white text-uppercase me-0 btn-save btn-label" onClick={onChangeStep}>
                              <i className="fas fa-save me-2 label-icon"></i>
                              Save & Next
                            </a>

                          )
                        }

                        <Link className=" btn ms-md-0 btn-red btn-label"
                          onClick={() => navigate(-1)}>
                          <i className='dripicons-cross me-2 icon-center label-icon'></i>CANCEL
                        </Link>
                      </div>
                    </div>
                    <div className=" mt-4">
                      {currentStep === 1 ? (
                        <DmProjectPlanIndex activitesLengths={activitesLengths} />
                      ) : currentStep === 2 ? (
                        <ApprovalDmStep currentStep={currentStep} approvalList={approvalList} />
                      ) : currentStep === 3 ? (
                        <FinalPlanDays resource={resource} key={3} finalList={finalList} />
                      ) : currentStep === 4 ? (
                        <CompleteDmStep currentStep={currentStep} />
                      ) : null}

                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainDmProjectPlan
