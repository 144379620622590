import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../assets/images/Digital Marketing/editEventImg.jpg"
//css
import "@fullcalendar/bootstrap/main.css";
import "../../../assets/css/Work/digitalMarketing.css"
//redux
import DeleteModal from "components/Common/DeleteModal";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Loader from "pages/Separate/Loader";
import Select from 'react-select'
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import classNames from "classnames";
import { addTimes, calculateQtyWithinTime, calculateTotalTime, FilteredArray, updateSpecificDate } from "utils/common-helpers";
import { DatePicker } from "antd";
import { daysOption } from "utils/common-options";

const DmProjectPlanIndex = ({ activitesLengths }) => {

  document.title = "Project Planning | Zithas Crm";
  const [event, setEvent] = useState({});
  const [activityName, setActiviyName] = useState('');
  const [qty, setQty] = useState('');
  const [addModal, setAddModal] = useState(false)
  const params = useParams();

  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (modal) {
        setModal(false)
      }
    }
  })

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [eventsList, setEvents] = useState([]);
  const [allEventsList, setAllEvents] = useState([]);
  const [extendedData, setExtendedData] = useState({});
  const [modalData, setModalData] = useState({})
  const [eventData, setEventData] = useState({});
  const [customDateHtml, setCustomDateHtml] = useState({})
  const authToken = secureLocalStorage.getItem('auth_token')
  const [assigneeOption, setAssigneeOption] = useState([])
  const [staffId, setStaffId] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [activities, setActivities] = useState([])
  const [allActivities, setAllActivities] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [changeKey, setChangeKey] = useState(false)
  const toggleLoading = (loading) => {
    setLoading(loading)
  }




  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modal, event]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
    } else {
      setModal(true);
    }
  };

  const handleDateClick = arg => {
    const clickedElement = arg.dayEl;
    if (clickedElement.querySelector('.resource-hours')) {
      return;
    }
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);
    toggle();
  };

  const handleEventClick = (arg, dragging) => {
    const event = arg.event;
    const data = event?._def?.extendedProps;
    setActiviyName(event?.title)
    setQty(data?.quantity)
    const dat = moment(event.start).format('YYYY-MM-DD');
    const htmlString = customDateHtml[dat];
    const timeMatch = htmlString ? htmlString.match(/(\d{2}:\d{2})/) : null;
    const time = timeMatch ? timeMatch[0] : "00:00";
    setExtendedData({
      ...data,
      occupied: time
    })
    if (dragging == false) {
      setIsEdit(true);
      toggle();
    }
  };

  const handleDeleteEvent = () => {
    if (event && event.id) {
      setEvents(prevEvents => prevEvents.filter(e => e.id !== event.id));
    }
    setDeleteModal(false);
    toggle();
  };

  const onDrag = event => {
    event.preventDefault();
  };


  const onDrop = async (event) => {
    const { date, draggedEl } = event;
    const categoryId = draggedEl.id;
    const quantity = draggedEl.getAttribute('data-quantity');
    const estTime = draggedEl.getAttribute('data-est');
    const type = draggedEl.getAttribute('type');


    if (!draggedEl || !draggedEl.classList.contains('external-event')) {
      return;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currentTime = new Date();

    const modifiedDate = new Date(
      year,
      month,
      day,
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    );
    const mainDate = moment(date).format('YYYY-MM-DD')
    const htmlString = customDateHtml[mainDate];
    const time = htmlString?.match(/>(.*?)\s*hrs/)[1];
    const newEvent = {
      title: draggedEl.innerText || draggedEl.getAttribute('title'),
      start: modifiedDate,
      className: draggedEl.className,
      id: categoryId,
      quantity: quantity,
      est_time: estTime,
      actual_time: calculateTotalTime(estTime, quantity),
      occupied: time
    };

    if (staffId?.length != 0) {
      const formEntries = {
        staff_id: staffId?.value,
        project_id: params?.id,
        activity_id: categoryId,
        type: params?.seo,
        quantity: quantity,
        start_date: mainDate,
        user_id: localStorage.getItem('auth_id'),
        className: draggedEl.className,
      }
      setActiviyName(draggedEl.innerText || draggedEl.getAttribute('title'))
      setQty(calculateQtyWithinTime(time, estTime, quantity))
      setModalData(formEntries)
      setEventData(newEvent)
      if (isDragging == false) {
        if (type == "event-edit") {
          setModal(true)
        } else {
          setAddModal(true)
        }
      } else {
        onDragEdit(mainDate)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please Select Staff"
      })
    }
  };




  useEffect(() => {
    const getDigitalData = async () => {
      const res = await fetchData(toggleLoading, `digitalProjectById/${params?.id}/${params?.seo}`, authToken);
      if (res?.success) {
        const details = res?.data;
        setAssigneeOption(details?.members?.map((obj) => ({
          value: obj.id,
          label: obj.alias_name,
        })))
        setStartDate(details?.project_data?.start_date)
        setEndDate(details?.project_data?.end_date)
        setAllEvents(details?.assign_activities?.map((obj) => ({
          title: obj.name,
          start: obj?.date,
          id: obj.id,
          task_id: obj.id,
          activity_id: obj?.activity_id,
          quantity: obj?.quantity,
          est_time: obj?.est_hrs,
          actual_time: calculateTotalTime(obj?.est_hrs, obj?.quantity),
          className: obj?.className,
          staff_id: obj?.staff_id,
        })))
        setAllActivities(details?.activities)
        setActivities(FilteredArray(details?.activities, details?.assign_activities))
      }
    }
    getDigitalData()
  }, [])

  const onGetAvailability = async (e) => {
    setStaffId(e)
    const formEntries = {
      user_id: e?.value,
      start_date: startDate,
      end_date: endDate,
    }
    const res = await FetchPostData(toggleLoading, "userAvailability", authToken, formEntries);
    if (res?.data?.success) {
      const output = res?.data?.data?.reduce((acc, { date, time }, index) => {
        acc[date] = `<div class="resource-hours" id="${index + 1}">${time} hrs</div>`;
        return acc;
      }, {});
      setCustomDateHtml(output)
      setChangeKey(!changeKey)
      setEvents(allEventsList?.filter((obj) => obj?.staff_id == e.value))
    }
  }

  const handleDatesSet = (arg) => {
    const dateCells = document.querySelectorAll('.fc-daygrid-day');
    dateCells.forEach((cell) => {
      const date = cell.getAttribute('data-date');
      if (customDateHtml[date]) {
        if (!cell.querySelector('.resource-hours')) {
          const div = document.createElement('div');
          div.innerHTML = customDateHtml[date];
          cell.appendChild(div);
        }
      }
    });

  };

  const onEditActivity = async (e) => {
    e.preventDefault();
    const formEntries = {
      staff_id: staffId?.value,
      project_id: params?.id,
      activity_id: extendedData?.activity_id,
      quantity: qty,
      user_id: localStorage.getItem('auth_id'),
      task_id: extendedData?.task_id,
      type: params?.seo,
    }
    const res = await FetchPostData(toggleLoading, "editDigitalProjectTask", authToken, formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Activity Updated Successfully"
      })
      setModal(false);
      onGetAvailability(staffId)
    }
  }

  const handleAddActivity = async (e) => {
    e.preventDefault();
    if (qty != 0) {
      const formEntries = { ...modalData, quantity: qty, est_hrs: calculateTotalTime(eventData?.est_time, qty) }
      const res = await FetchPostData(toggleLoading, "addDigitalProjectTask", authToken, formEntries)
      if (res?.data?.success) {
        setEvents((prevEvents) => [...prevEvents, eventData]);
        setCustomDateHtml(updateSpecificDate(customDateHtml, calculateTotalTime(eventData?.est_time, qty), modalData?.start_date))
        setActivities(FilteredArray(allActivities, res?.data?.data?.original?.data))
        setChangeKey(!changeKey)
        setAddModal(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "⚠️ Quantity cannot be zero! Please update it. ✨"
      })
    }
  }


  const handleBlur = (e) => {
    const convertToMinutes = (timeStr) => {
      const [hours, minutes] = timeStr.split(":").map(Number);
      return (hours * 60) + minutes;
    };
    const plannedTime = "08:00";
    const enteredTime = addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00");
    const plannedTimeInMinutes = convertToMinutes(plannedTime);
    const enteredTimeInMinutes = convertToMinutes(enteredTime);
    if (enteredTimeInMinutes > plannedTimeInMinutes) {
      Swal.fire({
        icon: "warning",
        title: "⏰ Time Exceeded",
        text: "Your total working hours have been exceeded. Please ensure to review and adjust accordingly.",
        confirmButtonText: "Okay",
        showCancelButton: true,
        confirmButtonText: '✅ Confirm',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          setQty(addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00"))
        }
      })
    }
  };

  const eventDidMount = (info) => {
    const { el, event } = info;
    el.setAttribute('data-est', event.extendedProps.est_time);
    el.setAttribute('data-quantity', event.extendedProps.quantity);
    el.setAttribute('id', event.extendedProps.id);
    el.setAttribute('type', 'event-edit');
    el.setAttribute('title', event?._def?.title);
  };

  const onDragEdit = async (mainDate) => {
    const formEntries = {
      staff_id: staffId?.value,
      project_id: params?.id,
      activity_id: extendedData?.activity_id,
      user_id: localStorage.getItem('auth_id'),
      task_id: extendedData?.task_id,
      start_date: mainDate,
      type: params?.seo,
    }
    const res = await FetchPostData(toggleLoading, "editDigitalProjectTask", authToken, formEntries);
    if (res?.data?.success) {
      setIsDragging(false);
      onGetAvailability(staffId)
    }
  }

  useEffect(() => {
    activitesLengths(activities?.length)
  }, [activities])


  return (
    <React.Fragment>
      <div id="credentialPage">
        <Row
          className={`gx-4 justify-content-between di-flex align-items-top `}
          id="cred"
        >
          <Col
            md={"12"}
            className={`z-1 pe-3 `}
          >
            <div className="card  h-100">
              {isLoading && (<Loader />)}
              <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteEvent}
                onCloseClick={() => setDeleteModal(false)}
              />
              <div className="">
                <div className="d-flex justify-content-end mb-3">
                  <div className="col-3">
                    <Select
                      placeholder="Select Member"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={staffId}
                      options={assigneeOption}
                      onChange={(e) => onGetAvailability(e)}
                    />
                  </div>
                </div>
                <Row className="mt-4">
                  <Col className="col-12">
                    <Row>
                      <Col lg={4}>
                        <Card>
                          <CardBody className="pt-0" >
                            <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "scroll", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                              <br />
                              <p className="text-muted ps-2">
                                Drag and drop your activity or click in the calendar
                              </p>
                              {activities &&
                                activities.map((category, i) => {
                                  const colors = ["primary", "info", "success", "danger"];
                                  const bgColor = colors[i % colors.length];
                                  return (
                                    <div className="d-flex justify-space-between align-items-center" key={i} >
                                      <div
                                        className={`bg-${bgColor} external-event fc-event text-white w-100 d-flex justify-space-between align-items-center`}
                                        id={category?.id}
                                        data-quantity={category?.quantity}
                                        data-est={category?.est_time}
                                        draggable
                                        onDrag={event => onDrag(event, category)}
                                      >
                                        <span>
                                          <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                          {category.activity}
                                        </span>
                                        <span className="badge bg-light text-dark ms-2"> {category.quantity}</span>
                                      </div>
                                    </div>
                                  )
                                }
                                )}
                              {activities?.length == 0 ? (
                                <p className="text-purple fw-bold text-center mt-3">🎉 Hurrah! All tasks have been planned and are ready. <br />Let's move on to the next step! 🚀</p>
                              ) : null}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={8}>
                        <div className="custom-initial">
                          <FullCalendar
                            key={changeKey}
                            plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                            slotDuration={"00:15:00"}
                            handleWindowResize={true}
                            themeSystem="bootstrap"
                            headerToolbar={{
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,dayGridWeek,dayGridDay",
                            }}
                            validRange={{
                              start: moment(startDate).format('YYYY-MM-DD'),
                              end: moment(endDate).format('YYYY-MM-DD'),    
                            }}
                            events={eventsList}
                            eventDidMount={eventDidMount}
                            editable={true}
                            droppable
                            selectable={true}
                            dateClick={handleDateClick}
                            eventClick={(e) => handleEventClick(e, false)}
                            drop={onDrop}
                            eventDragStart={(e) => {
                              setIsDragging(true)
                              handleEventClick(e, true)
                            }}
                            datesSet={handleDatesSet}
                            dayCellClassNames={() => [staffId?.length == 0 ? 'fc-day-other' : '']}
                          />
                        </div>

                        {/* New/Edit event modal */}
                        <Modal
                          isOpen={modal}
                          size="lg"
                          centered
                        >
                          <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
                            {isEdit ? "Edit Activity" : "Add Activity"}
                          </ModalHeader>
                          <ModalBody className="p-4">
                            <Form
                              onSubmit={(e) => {
                                onEditActivity(e)
                              }}
                            >
                              <Row>
                                <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                  <div className="text-center">
                                    <img
                                      src={editEventImg}
                                      alt="Addepe"
                                      style={{ width: "90%" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-7 d-flex flex-column justify-content-center align-items-start">
                                  <div className="d-flex gap-2 col-md-12 mb-5">
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                      <h5>Est Time</h5>
                                      <h5>{extendedData?.est_time} Hrs </h5>
                                    </a>
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                      <h5>Engaged Time</h5>
                                      <h5>{extendedData?.occupied} Hrs</h5>
                                    </a>
                                  </div>
                                  <div className="mb-3 col-12">
                                    <Label className="form-label">Activity Name</Label>
                                    <Input
                                      disabled
                                      name="title"
                                      type="text"
                                      value={activityName}
                                    />
                                  </div>
                                  <div className="row col-md-12">
                                    <div className="col-4">
                                      <Label className="form-label">Quantity</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Quantity"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}

                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row className="">
                                <Col className="col-12 text-end">
                                  <button type="submit" className="btn btn-success" id="btn-save-event">
                                    Save
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>

                        <Modal
                          isOpen={addModal}
                          size="lg"
                          centered
                        >
                          <ModalHeader toggle={() => setAddModal(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                            Add Activity
                          </ModalHeader>
                          <ModalBody className="p-4">
                            <Form
                              onSubmit={(e) => {
                                handleAddActivity(e)
                              }}
                            >
                              <Row>
                                <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                  <div className="text-center">
                                    <img
                                      src={editEventImg}
                                      alt="Addepe"
                                      style={{ width: "90%" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-7 d-flex flex-column justify-content-center align-items-start pl-0">
                                  <div className="d-flex gap-2 col-md-12 mb-5">
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                      <h5>Est Time</h5>
                                      <h5>{eventData?.est_time} Hrs </h5>
                                    </a>
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                      <h5>Engaged Time</h5>
                                      <h5>{eventData?.occupied} Hrs</h5>
                                    </a>
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                      <h5>Time Taken</h5>
                                      <h5>
                                        {addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00")} Hrs</h5>
                                    </a>
                                  </div>
                                  <div className="mb-3 col-12">
                                    <Label className="form-label">Activity Name</Label>
                                    <Input
                                      disabled
                                      name="title"
                                      type="text"
                                      value={activityName?.replace(/\d+/g, "")}
                                    />
                                  </div>
                                  <div className="row col-md-12 align-items-end">
                                    <div className="col-4">
                                      <Label className="form-label">Total Quantity</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Quantity"
                                        value={eventData?.quantity}
                                        disabled

                                      />
                                    </div>
                                    <div className="col-4">
                                      <Label className="form-label">Quantity</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Quantity"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row className="">
                                <Col className="col-12 text-end">
                                  <button type="submit" className="btn btn-success" id="btn-save-event">
                                    Save
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

DmProjectPlanIndex.propTypes = {
  className: PropTypes.string,
};

export default DmProjectPlanIndex;
