import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import "../../../assets/css/Scollbar.css"
import "../../../assets/css/Work/projects.css"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import CompleteSprintCalendar from "./CompleteSprintCalendar"

const CompleteSprint = () => {
  document.title = "Future Plan | Zithas Crm"
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Digital Marketing" breadcrumbItem="Sprint Planning" />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center wizard">
                    <div className="col-12 d-flex gap-2 justify-space-between align-items-center">
                      <div className="col-6 d-flex justify-content-start gap-5 align-items-center">

                        <div className="d-flex justify-content-start align-items-center">
                          <h4>Sprint Planning</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-4">
                    <CompleteSprintCalendar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompleteSprint
