import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import zithas from "../../../assets/images/main-zithas.png"
import { Tooltip } from "antd"
import configure from "configure"

const DeveloperGit = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const getAbsentees = async () => {
      const response = await fetchData(toggleLoading, "dashboardData/2", "")
      if (response?.success) {
        setData(response?.data?.git_user)
      }
    }
    getAbsentees()
  }, [])
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div>
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Git Push Pending</p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div className="responsive-table mb-4">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="header col-12 text-center">Developers</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          <Tooltip color={"#556de6"} title={obj?.alias_name}>
                            <img
                              src={`${
                                obj?.avatar != null
                                  ? `${configure.imgUrl}${obj?.avatar}`
                                  : zithas
                              } `}
                              alt="user Image"
                              style={{
                                borderRadius: "50px",
                                width: "30px",
                                height: "30px",
                              }}
                              title={obj?.alias_name}
                              className="img-fluid cursor-pointer object-fit-cover"
                            />
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {data?.length == 0 ? (
                <p className="text-center text-purple fw-bold mt-3">
                  🎉 All set! No Git push pending! 🚀
                </p>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default DeveloperGit
