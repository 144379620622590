import React, { useState, useMemo, useEffect } from "react";
import { Row, Col, Card, Table, Modal, Label, Form } from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
// assets
import Loader from "pages/Separate/Loader";
import NewPagination from "components/Common/Newpagination";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import leaveImg2 from "../../../assets/images/LeaveDetails.jpg";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import Swal from "sweetalert2";
import { customGlobalFilter } from "utils/common-helpers";
import { leaveColumns } from "utils/columns/ElementsColumns/leaveColumns";
import { ResizableBox } from "react-resizable";
import Draggable from "react-draggable";
import moment from "moment";

function TableContainer({ columns, data, members, toggleLoading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      globalFilter: customGlobalFilter,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize, globalFilter } = state;

  useEffect(() => {
    setPageSize(10);
  }, []);
  // Render the UI for your table

  return (
    <div>
      <Row>
        <Col xl="12">
          <div className="card-body ">
            <div className="d-flex justify-content-between mb-2">
              <div className="mb-3 text-purple fs-5  fw-bold">
                Applied Leaves
              </div>
            </div>
            <Row className="mb-2 justify-content-between">
              <div className="mb-3 mb-md-0 d-flex justify-space-between align-items-center">
                <div className="col-1 me-md-5">
                  <select
                    className="border-radius form-select"
                    style={{ width: "100px" }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-10 d-flex justify-content-end align-items-center">
                  <div className="col-4 cusrtom_search_box">
                    <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                      <div className="">
                        <div className="position-relative">
                          <label
                            htmlFor="search-bar-0"
                            className="search-label w-100 mb-0"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="border-radius form-control rounded h-100 w-100"
                              placeholder={`Search...`}
                              value={globalFilter || ""}
                            />
                          </label>
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="position-relative table-responsive">
              <Table className="table table-bordered mt-4" {...getTableProps()}>
                <thead className={`fixed_view_payment_table`}>
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <span className="flex-grow-1  header-text">
                              {column.render("Header")}
                            </span>
                            {column.isShortIcon && (
                              <span
                                onClick={() =>
                                  column.getSortByToggleProps().onClick()
                                }
                                className="d-flex align-items-center ms-2"
                              >
                                <i
                                  title="Ascending"
                                  className={`bi bi-arrow-up info-icon ${
                                    column.isSorted && !column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                                <i
                                  title="Descending"
                                  className={`bi bi-arrow-down info-icon ${
                                    column.isSorted && column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  className="fix-introducer-header"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const rowBackgroundColor =
                      row?.original?.leave_type == 2
                        ? "bg-light-red2"
                        : "bg-light-blue2";

                    return (
                      <tr
                        className={`row-hover ${rowBackgroundColor}`}
                        {...row.getRowProps()}
                        key={i}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${
                                cell.column.className
                              }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
                nextPage={nextPage}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const AppliedLeaves = () => {
  const [isLoading, setLoading] = useState(false);
  const [modal_Access, setAccessDenied] = useState(false);
  const [leaveDetail, setLeaveDetails] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [addReasons, setAddReasons] = useState(false);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [reason, setReason] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [reasons, setReasons] = useState("");
  const [leaveSlots, setLeaveSlots] = useState([]);
  const [allLeave, setAllLeave] = useState([]);
  const [slotId, setSlotId] = useState("");
  const [staffDetail, setStaffDetail] = useState({});
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  const onLeaveDetail = async (id, staff_id, obj) => {
    const res = await fetchData(toggleLoading, `LeaveSlots/${id}`, "");
    if (res?.success) {
      setStaffDetail(obj);
      setLeaveDetails(true);
      setLeaveId(id);
      setDetails(res?.data);
      setReason(res?.data?.leaveslots[0]?.comment);
      setLeaveSlots(res?.data?.leaveslots);
      setAllLeave(res?.data?.leaveslots);
      setStaffId(staff_id);
    }
  };
  const onChangeStatus = async (id, status, disapprove) => {
    const formEnties = {
      data_id: id,
      status: status,
      type: 1,
      leave_reason: disapprove ? reasons : "",
    };
    const res = await FetchPostData(
      toggleLoading,
      `approveList`,
      "",
      formEnties
    );
    if (res?.data?.success) {
      setAddReasons(false);
      setReasons("");
      Swal.fire({
        icon: "success",
        text: `Leave ${
          status == "Cancel" ? "Cancelled" : "Approved"
        }  Successfully`,
      }).then(async (data) => {
        const response = await fetchData(
          toggleLoading,
          `LeaveSlots/${leaveId}`,
          ""
        );
        if (response?.success) {
          setLeaveSlots(response?.data?.leaveslots);
          setAllLeave(response?.data?.leaveslots);
        }
      });
    }
  };

  const column1 = useMemo(() => leaveColumns(onLeaveDetail), []);
  useEffect(() => {
    const fetchLeaves = async () => {
      const res = await fetchData(toggleLoading, `approvalList/${1}`, "");
      if (res?.success) {
        setData(res?.data);
      }
    };
    fetchLeaves();
  }, []);

  const onGetHisory = async () => {
    const res = await fetchData(toggleLoading, `LeaveHistory/${staffId}`, "");
    if (res?.success) {
      setLeaveSlots(res?.data?.leaveslots);
      setShowHistory(true);
    }
  };
  return (
    <div>
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="">
          <div className="position-relative">
            <Row>
              <Col lg="12">
                <Card>
                  <div className="responsive-table">
                    <TableContainer
                      data={data}
                      toggleLoading={toggleLoading}
                      columns={column1}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal isOpen={addReasons} size="md" className="" centered>
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">Add Reason</h5>
            <button
              type="button"
              onClick={() => {
                setAddReasons(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body pe-4 ">
            <form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                onChangeStatus(slotId, "Unapproved", true);
              }}
            >
              <div className="col-md-12 ">
                <label>Reason</label>
                <textarea
                  className="form-control"
                  placeholder="Reason for Disapproval"
                  value={reasons}
                  onChange={(e) => setReasons(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mt-3">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i> save
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={leaveDetail} size="xl" className="" centered>
          <div className="modal-header pe-4">
            <div className="d-flex gap-3 col-md-9 justify-content-between">
              <h5 className="modal-title mt-0">Leave Details</h5>
              <div className="d-flex gap-3">
                <h5 className="text-purple fw-bold mb-0">
                  <i className="bx bxs-id-card me-2"></i> -{" "}
                  {staffDetail?.emp_id}
                </h5>
                <div>|</div>
                <h5 className="text-purple fw-bold mb-0">
                  <i className="bx bxs-user me-2"></i> - {staffDetail?.name}
                </h5>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setLeaveDetails(false);
                setShowHistory(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body pe-4 my-3">
            <Form className="row d-flex justify-content-center align-items-center">
              <div className="col-md-5 d-flex flex-column justify-content-end align-items-center ps-3 gap-4">
                <div className="text-center">
                  <img src={leaveImg2} alt="Addepe" style={{ width: "70%" }} />
                </div>
              </div>

              <div className="col-md-7 d-flex flex-column justify-content-start  gap-3">
                <div className="col-md-12 d-flex flex-row mb-3 mt-2 gap-3">
                  <div className="col-md-12 d-flex justify-content-start align-items-center gap-3">
                    <h5 className="mb-0">Leave Balance :</h5>
                    <div className="d-flex justify-content-between align-items-center gap-3 col-md-9">
                      <div className="d-flex gap-3 justify-content-start align-items-center">
                        <span className="mb-0 px-3 py-1  bg-primary rounded text-light">
                          <b>
                            EL : <span>{details?.earned_leave}</span>
                          </b>
                        </span>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                          <b>
                            CL : <span>{details?.casual_leave}</span>
                          </b>
                        </span>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                          <b>
                            SL : <span>{details?.sick_leave}</span>
                          </b>
                        </span>
                      </div>
                      <div>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light me-2">
                          <b>
                            Total : <span>{leaveSlots?.length}</span>
                          </b>
                        </span>
                        <span
                          className="mb-0 px-3 py-1 rounded  bg-success text-light cursor-pointer"
                          onClick={() => {
                            if (showHistory) {
                              setLeaveSlots(allLeave);
                              setShowHistory(false);
                            } else {
                              onGetHisory();
                            }
                          }}
                        >
                          <b>
                            <i
                              className={
                                showHistory ? "fab fa-rev" : "fa fa-history"
                              }
                            ></i>
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {showHistory == false && (
                  <div className="col-12 mb-3">
                    <label className="">Reasons</label>
                    <textarea
                      className="form-control"
                      value={reason}
                      disabled
                    />
                  </div>
                )}
                <div className="d-flex flex-column gap-1">
                  <h5 className="mb-0 ps-1 mb-2">
                    {showHistory ? "Leave History" : "Leave Slots"} :{" "}
                  </h5>
                  <div
                    className={`table-responsive col-md-12 ${
                      showHistory
                        ? "leave-history-slots"
                        : "leave-history-table"
                    }`}
                  >
                    <Table className="table mb-0">
                      <thead
                        className="bg-white"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <tr className=" ">
                          <th className="header text-center col-1">
                            Leave Date
                          </th>
                          <th className="header col-1">Type</th>
                          <th className="header col-1">Status</th>
                          <th
                            className="text-center header"
                            style={{ width: "10%" }}
                          >
                            {showHistory ? "Reason" : "Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {leaveSlots?.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row" className="text-center">
                                {moment(obj?.leave_dates).format("DD-MM-YYYY")}
                              </th>
                              <td>
                                {obj?.leave_name}
                                {obj?.medical_certificate != null ? (
                                  <a
                                    className="ms-2"
                                    href={obj?.medical_certificate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-download"></i>
                                  </a>
                                ) : null}
                              </td>
                              <td>{obj?.leave_status}</td>
                              <td
                                className="word-wrap text-center"
                                style={{ width: "10%" }}
                              >
                                {showHistory ? (
                                  obj?.comment
                                ) : (
                                  <>
                                    {obj?.status != 1 ? (
                                      <>
                                        <a
                                          className="btn btn-sm btn-outline-primary me-1 lh-1 p-1"
                                          title="Approve"
                                          onClick={() =>
                                            onChangeStatus(
                                              obj?.slots_id,
                                              "Approved"
                                            )
                                          }
                                        >
                                          <i className="fas fa-thumbs-up"></i>
                                        </a>
                                        <a
                                          className="btn btn-sm btn-outline-danger me-1 lh-1 p-1"
                                          title="Disapprove"
                                          onClick={() => {
                                            setSlotId(obj?.slots_id);
                                            setAddReasons(true);
                                          }}
                                        >
                                          <i className="fas fa-thumbs-down"></i>
                                        </a>
                                        <a
                                          className="btn btn-sm btn-outline-secondary me-1 lh-1 p-1"
                                          title="Approve"
                                          onClick={() =>
                                            onChangeStatus(
                                              obj?.slots_id,
                                              "Cancel"
                                            )
                                          }
                                        >
                                          <i className="fas fa-times"></i>
                                        </a>
                                      </>
                                    ) : (
                                      <>{obj?.leave_reason || "-"}</>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        <AccessDeniedPopup
          modal_Access={modal_Access}
          setAccessDenied={setAccessDenied}
        />
      </React.Fragment>
    </div>
  );
};

export default AppliedLeaves;
